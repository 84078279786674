import React from "react";
import { LocationIcon, Check } from "../../assets/icons/icons";
//import Draggable from "react-draggable";
import "./styles.scss";
const PostCard = ({
  loading,
  src,
  title,
  subtitle,
  locations,
  onClick,
  className,
  time,
  clicks,
  selected,
  expire,
  onEditClick,
  forwardedRef,
  ...props
}) => {
  return (
    (loading && (
      <div className="image_placeholder deal_card">
        <div className="post_card_image" />

        <div>
          <div className="flex_row_top">
            <div className="name_placeholder" />

            <div className="flex_column width_full">
              <div className="title_placeholder" />
              <div className="name_placeholder" />
            </div>
          </div>

          <div className="body_placeholder" />
        </div>
      </div>
    )) || (
      <figure
        className={`post_card cursor_grab ${className}`}
        ref={forwardedRef}
        {...props}
      >
        <img
          className="post_card_image"
          src={src}
          alt={title}
          // style={{
          //   backgroundImage: `url(${src})`,
          //   backgroundSize: "cover",
          //   backgroundRepeat: "no-repeat",
          //   backgroundPosition: "top",
          //   backgroundColor: "#acacac",
          //   width: "120%",
          //   height: "98px",
          //   borderRadius: "10px 10px 0 0",
          // }}
        />
        <button
          className="card_check_box flex_row_center cursor_pointer"
          onClick={onClick}
        >
          {selected ? (
            <Check width="20px" height="20px" fill="#FF0000" />
          ) : null}
        </button>
        <div className="card_captions">
          <figcaption className="normal_16px bold mb_5">{title}</figcaption>
          <figcaption className="normal_18px ellipses_end mb_15">
            {subtitle}
          </figcaption>
          <div className="flex_row_between mb_15">
            <div className="flex_row_center">
              <LocationIcon
                fill="#FF0000"
                width="12px"
                height="12px"
                className="mr_10"
              />

              <label className="normal_16px">{locations}</label>
            </div>
            <label className="gray_label">{time}</label>
          </div>
          <div className="flex_row_between mb_15">
            <label className="gray_label">{clicks}</label>
            <a href="#/" onClick={onEditClick}>
              <label className="gray_label">view & edit {">"}</label>
            </a>
          </div>
          <div className="flex_row_center text_center normal_16px no_wrap">
            {expire}
          </div>
        </div>
      </figure>
    )
  );
};

export default PostCard;
