import React from "react";
import "./scss/app.scss";
import { observer } from "mobx-react";
import HomePage from "./screens/homePage";

import DealsPage from "./screens/dealsPage";
import AddDealsPage from "./screens/addDiscount";
import NotificationsPage from "./screens/notificationPage";
import ScheduledNotification from "./screens/notificationPage/scheduled.js";
import AddScheduledNotification from "./screens/notificationPage/addscheduled.js";
import CompletedScheduledNotification from "./screens/notificationPage/addscheduled.js";
import InactiveDeals from "./screens/dealsPage/inactiveDeals";
import InactivePosts from "./screens/homePage/inactivePost";
// import LocationSettings from "./screens/locationSettings";
import Login from "./screens/login";
import Signup from "./screens/signup";
import NotFound from "./screens/notFound";

import {
	// Auth
	LOGIN,
	SIGNUP,
	//   Dashboard
	HOME,
	DEALS_DISCOUNTS,
	ADD_DEALS,
	INACTIVE_DEALS,
	INACTIVE_POSTS,
	PUSH_NOTIFICATIONS,
	LOCATION_SETTINGS,
	SCHEDULED_NOTIFICATION,
	ADD_SCHEDULED_NOTIFICATION,
	COMPLETED_SCHEDULED_NOTIFICATION,
} from "./router/router";
import AuthRoute from "./router/AuthRoute";
import UnauthRoute from "./router/UnauthRoute";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function App() {
	return (
		<Router>
			<Switch>
				<AuthRoute exact path="/" component={HomePage} />
				<AuthRoute exact path={HOME} component={HomePage} />
				<AuthRoute exact path={DEALS_DISCOUNTS} component={DealsPage} />
				<AuthRoute exact path={ADD_DEALS} component={AddDealsPage} />

				<AuthRoute
					exact
					path={PUSH_NOTIFICATIONS}
					component={NotificationsPage}
				/>
				<AuthRoute
					exact
					path={SCHEDULED_NOTIFICATION}
					component={ScheduledNotification}
				/>
				<AuthRoute
					exact
					path={ADD_SCHEDULED_NOTIFICATION}
					component={AddScheduledNotification}
				/>
				<AuthRoute
					exact
					path={COMPLETED_SCHEDULED_NOTIFICATION}
					component={CompletedScheduledNotification}
				/>
				<AuthRoute exact path={INACTIVE_DEALS} component={InactiveDeals} />
				<AuthRoute exact path={INACTIVE_POSTS} component={InactivePosts} />

				<UnauthRoute exact path={LOGIN} component={Login} />
				<UnauthRoute exact path={SIGNUP} component={Signup} />
				{/* Not Found */}
				<Route path="*" component={NotFound} />
			</Switch>
		</Router>
	);
}

export default observer(App);
// <AuthRoute
//           exact
//           path={LOCATION_SETTINGS}
//           component={LocationSettings}
//         />
