import React from "react";
import { Route, Redirect } from "react-router-dom";
import Cookies from "js-cookie";
//import { HOME } from "./router";
const UnauthRoute = ({ component: Component, authenticated, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        !Cookies.get("token") ? (
          <Route render={() => <Component {...props} />} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};
export default UnauthRoute;
