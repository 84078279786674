import { observable, runInAction, action } from "mobx";
import dataService from "../../services/data.service";
import { GET_USERS_URL } from "../../helpers/urls";
import errorHandler from "../../helpers/errorHandler";
import Cookies from "js-cookie";
export class UserStore {
  @observable authrorized = false;
  @action getAuthorized = () => {
    let token = Cookies.get("token");
    this.authrorized = token ? true : false;
    return this.authrorized;
  };
  @observable users = [];
  @observable dataCached = false;
  getUsers = async (alert, show) => {
    try {
      let response = await dataService.getAuthData(GET_USERS_URL);

      runInAction(() => {
        let res = response.data && response.data.data;

        this.users = res;
        show &&
          alert.show("users loaded successfully!", {
            type: "success",
          });

        this.dataCached = true;
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };
}

export default new UserStore();
