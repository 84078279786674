import React, { forwardRef } from "react";
import { Check } from "../../assets/icons/icons";
import "./styles.scss";

const CheckBoxRound = ({
  id,
  label,
  checked,
  disabled,
  onClick,
  className = "flex_row_center",
}) => {
  return (
    <div className={`checkbox_container_round mb_15 ${className}`}>
      <button
        type="button"
        className={`checkbox_round cursor_pointer mr_10 ${
          checked ? "checkbox_round_active" : ""
        }`}
        disabled={disabled}
        onClick={onClick}
        id={id}
      />

      <label htmlFor={id} className="normal_17px cursor_pointer no_wrap">
        {label}
      </label>
    </div>
  );
};
const CheckBox = forwardRef(
  ({ props, ref, id, label, checked, disabled, onClick, className, mb="mb_15" }) => {
    return (
      <div
        className={`checkbox_container_square flex_row_center cursor_pointer ${mb} ${className}`}
        ref={ref}
        onClick={onClick}
        disabled={disabled}
      >
        <button
          type="button"
          className={`checkbox_box cursor_pointer  mr_15 ${
            checked ? "checkbox_box_active" : ""
          }`}
          disabled={disabled}
        
          id={id}
        >
          <Check
            width="11px"
            height="11px"
            fill={`${checked ? "#FF0000" : "#FFFFFF"}`}
          />
        </button>

        <div htmlFor={id} className="normal_17px cursor_pointer">
          {label}
        </div>
      </div>
    );
  }
);

export { CheckBoxRound, CheckBox };
