import React from "react";
import { useHistory } from "react-router-dom";

const NotFound = () => {
  const history = useHistory();
  return (
    <div className="flex_column_center">
      <h3 className="mt_25"> Error 404!~</h3> <br /> <h1>Page not found</h1>
      <a className="cursor_pointer" onClick={() => history.goBack()}>
        go back
      </a>
    </div>
  );
};
export default NotFound;
