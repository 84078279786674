import axios from "axios";
import { withRouter } from "react-router-dom";
import Cookies from "js-cookie";
const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

class DataService {
  /***********************************Get data********************* */
  async getAuthData(url) {
    const token = await Cookies.get("token");
    return axios
      .get(API_URL + url, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        return response;
      });
  }

  /***********************************Get data********************* */
  async getInternalData(url) {
    const token = await Cookies.get("token");
    return axios
      .get(API_URL + url, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "x-api-key": API_KEY,
        },
      })
      .then((response) => {
        return response;
      });
  }

  /***********************************Post data********************* */

  async postAuthData(url, data) {
    const token = await Cookies.get("token");
    return axios
      .post(API_URL + url, data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "x-api-key": API_KEY,
        },

      })

      .then((response) => {
        return response;
      });
  }


  /***********************************Post data********************* */
  async postInternalData(url, data) {
    const token = await Cookies.get("token");
    return axios
      .post(API_URL + url, data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "x-api-key": API_KEY,
        },
      })

      .then((response) => {
        return response;
      });
  }

  /***********************************Put data********************* */

  async putAuthData(url, data) {
    const token = await Cookies.get("token");
    return axios
      .put(API_URL + url, data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })

      .then((response) => {
        return response;
      });
  }

  /***********************************Put data********************* */

  async deleteAuthData(url) {
    const token = await Cookies.get("token");
    return axios
      .delete(API_URL + url, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        return response;
      });
  }
}

export default withRouter(new DataService());
