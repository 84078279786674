/** @format */

import React, { useEffect } from "react";
import { observer } from "mobx-react";
import Modal from "../modal";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import './styles.scss'
import commonStore from "../../stores/dataStore/commonStore";
import { useState } from "react";
const LocationModal = observer(({
  setWithLocation,
  address,
  setAddress,
  setCoordinate,
  locationModal,
  setLocationModal,
  alert
}) => {

  const [locationError, setLocationError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [result, setResult] = useState(false)


  const searchOptions = {
    componentRestrictions: { country: ["ng"] },
  };

  const handleChange = async (value) => {
    setLoading(true)
    setLocationError(false)
    setCoordinate('');
    setAddress(value)
    const result = await geocodeByAddress(value);
    const LngLat = await getLatLng(result[0]);
    setResult(LngLat)
    let latLngData = {
      lat: LngLat.lat,
      lng: LngLat.lng,
    };
    await commonStore.getCokitchenPolygonId(alert, latLngData);
    if (commonStore?.coordinatesDetails?.explore) {
      setLocationError(true)

    } else {
      setCoordinate(LngLat);
    }
    setLoading(false)
  };

  return (
    <Modal
      showModal={locationModal}
      goBackClick={() => {
        setAddress('')
        setLocationModal(!locationModal)
        setWithLocation(false)
      }
      }
      closeModal={() => {
        setLocationModal(!locationModal)
      }}

      loading={!address || locationError || loading || !result}
      onSubmit={(e) => {
        e.preventDefault();
      }}

      buttonOnClick={() => {
        setWithLocation(true)
        setLocationModal(!locationModal)
      }
      }
      buttonText={'Use Address'}
      title="Enter Address:"
      goBack='Remove Address'
      customContent={

        <>
          <PlacesAutocomplete
            value={address}
            onChange={setAddress}
            onSelect={handleChange}
            searchOptions={searchOptions}

          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div className=" input_container">
                <input
                  {...getInputProps({
                    placeholder: "enter address...",
                    className: "input-box",
                  })}
                />
                <div className="">
                  {loading && <div>Loading...</div>}
                  {suggestions.map((suggestion, i) => {
                    const className = suggestion.active
                      ? "p_10 "
                      : "p_10";
                    // inline style for demonstration purpose
                    const style = suggestion.active
                      ? {
                        backgroundColor: "#FED7AA99",
                        transition: "all .3s",
                        cursor: "pointer",
                      }
                      : { backgroundColor: "#ffffff", cursor: "pointer" };

                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                        key={i}
                      >
                        <span className="text-color">{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>

          {
            locationError && <p style={{
              color: 'red',
              fontSize: '14px',
              margin: '12px 0'
            }}> We currently don't deliver here. Enter Location Within Cokitchen Polygon !</p>
          }
        </>

      }
    />
  );
});

export default LocationModal;
