import React,{useEffect, useState} from "react";
export const Countdown=({counter, setCounter}) =>{

    useEffect(() => {
   
        const timer =
          counter > 0 && setInterval(() => setCounter(prev=>prev-1), 1000);
        return () => clearInterval(timer);
      }, [counter]);

     
return "Button will appear in " + counter + " seconds"  

}