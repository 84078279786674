import React, { useRef, createRef, useEffect } from "react";
import { SettingsIcon } from "../../assets/icons/icons";
import "./styles.scss";
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom'

import ROUTES from "../../helpers/routes";
const Tabs = () => {
  const location = useLocation();
  const myRefs = useRef([]);
  myRefs.current = ROUTES.map((element, i) => myRefs.current[i] ?? createRef());
  useEffect(() => {
    let currRef = myRefs?.current?.find(item => item?.route === location?.pathname)
    currRef && currRef?.current?.scrollIntoView({ behavior: 'smooth' })


  }, [])
  return (
    <div className="tabs_container">
      <ul className="flex_row_align_center">
        {ROUTES.map((item, i) => {

          if (item?.name === "Location settings") {
            return (
              <li ref={myRefs.current[i]} key={item?.name} className={`nav_setting${location?.pathname === item?.route ? "active_nav_item nav_item_first" : ""}`}>
                <Link to={item?.route}>
                  <div className="flex_row_end cursor_pointer">
                    <span className="flex_row_center">
                      <SettingsIcon fill="#B9B9B9" width="20px" height="20px" />
                    </span>
                    <label className="normal_18px p_20">{item?.name}</label>
                  </div>
                </Link>
              </li>)
          }
          else {
            return (
              <li ref={myRefs.current[i]} key={item?.name} className={`nav_item ${location?.pathname === item?.route ? "active_nav_item" : ""}`}>
                <Link to={item?.route}>
                  <div className="normal_18px p_20"> {item?.name}</div>
                </Link>
              </li>
            )
          }

        })}

      </ul>
    </div>
  );
};

export default Tabs;
