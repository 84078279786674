import React from "react";
import DateTime from "react-datetime";
import "./styles.scss";

const IconInput = ({
  label,
  name,
  className,
  type,
  placeholder,
  value,
  onChange,
  disabled,
  required,
  max,
  min,
  icon,
  subIcon,
  isValidDate,
  dateValue,
  onDateChange,
  dateFormat,
  isDate,
  containerClass,
  step,
}) => {
  return (
    <div
      className={`input_container_icons flex_column ${containerClass} ${
        dateFormat !== false ? "date_input" : "time_input"
      }`}
    >
      {label && <label className="mb_15 normal_17px">{label}</label>}
      <div className="flex_row input_box">
        <span className="mr_10">{icon}</span>
        {isDate ? (
          <DateTime
            isValidDate={isValidDate}
            value={dateValue}
            onChange={onDateChange}
            dateFormat={dateFormat}
            timeFormat={dateFormat === false ? true : false}
            disabled
          />
        ) : (
          <input
            max={max}
            min={min}
            name={name}
            className={`input ${className}`}
            type={type}
            placeholder={placeholder}
            value={value}
            autoComplete="on"
            disabled={disabled}
            onChange={(e) => onChange(e.target.value)}
            required={required}
            step={step}
          />
        )}
        <span className="ml_10 sub_icon">{subIcon}</span>
      </div>
    </div>
  );
};
export default IconInput;
