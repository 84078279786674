import React from "react";
import "./styles.scss";

const Input = ({
  label,
  name,
  className,
  type,
  placeholder,
  value,
  onChange,
  disabled,
  required,
  max,
  min,
  containerClass,
  maxLength,
  step,
}) => {
  return (
    <div className={`input_container flex_column ${containerClass}`}>
      {label && (
        <label className="mb_15  normal_17px capitalize">{label}</label>
      )}
      <input
        max={max}
        min={min}
        maxLength={maxLength}
        name={name}
        className={`input ${className}`}
        type={type}
        placeholder={placeholder}
        value={type === "file" ? null : value}
        autoComplete="on"
        disabled={disabled}
        onChange={(e) => {
          type === "file"
            ? onChange(e.target.files[0])
            : onChange(e.target.value);
        }}
        required={required}
        step={step}
      />
    </div>
  );
};
export default Input;
