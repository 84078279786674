import React from "react";
import { Editor } from "react-draft-wysiwyg";
import "./styles.scss";
const TextEditor = ({
  placeholder,
  editorState,
  onEditorStateChange,
  defaultEditorState,
  containerClass,
}) => {
  return (
    <div className={`text_editor_container mt_35 ${containerClass}`}>
      <Editor
        editorState={editorState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        onEditorStateChange={onEditorStateChange}
        placeholder={placeholder}
        //    defaultEditorState={defaultEditorState}
      />
    </div>
  );
};
export default TextEditor;
