import React from "react";
import { Link } from "react-router-dom";
import { Clock } from "../../assets/icons/icons";
import { ADD_DEALS } from "../../router/router";
import "./styles.scss";
const DealCard = ({
  loading,
  src,
  title,
  subtitle,
  name,
  className,
  code,
  badgeTime,
  logo_src,
  frequency,
  price,
  expiresTime,
  id = "000",
  rate,
  expiresDate,
}) => {
  return (
    (loading && (
      <div className="image_placeholder deal_card">
        <div className="post_card_image" />
        <div className="time_span" />
        <div>
          <div className="flex_row_top">
            <div className="mr_10 brand_logo_placeholder" />
            <div className="flex_column width_full">
              <div className="title_placeholder" />
              <div className="name_placeholder" />
            </div>
          </div>

          <div className="body_placeholder" />
        </div>
      </div>
    )) || (
      <figure className={`deal_card ${className}`}>
        <div
          className="post_card_image"
          // src={src}
          // alt={title}
          style={{
            backgroundImage: `url(${src})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundColor: "#acacac",
            width: "100%",
            height: "98px",
            borderRadius: "10px 10px 0 0",
          }}
        />
        <span className="time_span flex_row_center gray normal_14px">
          <Clock fill="#ADADAD" width="8px" height="8px" className="mr_10" />
          {badgeTime}
        </span>
        <div className="card_captions">
          <div className="flex_row_top">
            <img src={logo_src} alt="brand_logo" className="mr_10" />
            <div className="flex_column width_full">
              <figcaption className="normal_16px bold mb_5">{title}</figcaption>
              <figcaption className="gray bold normal_14px mb_4">
                {name}
              </figcaption>
              <figcaption className="gray ellipses_end_two normal_14px mb_15">
                {subtitle}
              </figcaption>

              <div className="flex_row_between mb_15">
                <label className="bold_21px">{code}</label>
                <Link to={`add-deals/${id}`} className="no_decor">
                  <label className="gray normal_16px cursor_pointer ">
                    view & edit {"  >"}
                  </label>
                </Link>
              </div>
            </div>
          </div>
          <div className="flex_row_between mb_15">
            <label className="normal_16px">
              Used {frequency} {frequency === 1 ? "time" : "times"}
            </label>

            <label className="normal_16px">₦{price}</label>
          </div>

          <div className="flex_row_center text_center normal_16px">
            Expires by {expiresTime} ({expiresDate})
          </div>
        </div>
      </figure>
    )
  );
};

export default DealCard;
