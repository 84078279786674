import React, { useState, useEffect } from "react";
import "./styles.scss";
import Header from "../../components/header";
import Tab from "../../components/tabs";
import { Button } from "../../components/buttons";
import DealCard from "../../components/dealCard";
import NavItem from "../../components/sideNavItem";
import { PlusIcon, LocationIcon } from "../../assets/icons/icons";
import { useHistory } from "react-router-dom";
import DealStore from "../../stores/dataStore/dealStore";
import UserStore from "../../stores/userStore/user";
import { withAlert } from "react-alert";
import CommonStore from "../../stores/dataStore/commonStore";
import Loader from "react-loader-spinner";
import moment from "moment";
import BrandStore from "../../stores/dataStore/brandInfoStore";
import { ArrowBack } from "../../assets/icons/icons";
import { DEALS_DISCOUNTS } from "../../router/router";
const InactiveDeals = ({ alert }) => {
  const history = useHistory();
  const [deals, setDeals] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ckLoading, setCkLoading] = useState(false);

  const [cokitchens, setCokitchens] = useState([]);
  const [currentCokitchen, setCurrentCokitchen] = useState("");
  const [currentDeals, setCurrentDeals] = useState([]);

  const [currentDealsPlaceHolder, setCurrentDealsPlaceHolder] = useState([
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
  ]);
  const [showSide, setShowSide] = useState(true);
  const [pageWidth, setPageWidth] = useState("");

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  useEffect(() => {
    setPageWidth(window.innerWidth);
    if (pageWidth <= 628) {
      setShowSide(false);
    } else {
      setShowSide(true);
    }
  }, [pageWidth]);
  useEffect(() => {
    const handleWindowResize = () => {
      setPageWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, [pageWidth]);

  // load deals from store
  useEffect(async () => {
    if (
      !DealStore.dataCached &&
      !UserStore.dataCached &&
      !BrandStore.dataCached
    ) {
      setLoading(true);
      setCkLoading(true);
      await Promise.all([
        DealStore.getDeals(alert),
        DealStore.getDealTypes(alert),
        UserStore.getUsers(alert, false),
        BrandStore.getBrands(alert),
      ]);
    } else if (!DealStore.dataCached && BrandStore.dataCached) {
      await Promise.all([
        DealStore.getDeals(alert),
        DealStore.getDealTypes(alert),
      ]);
    } else if (
      DealStore.dataCached &&
      !UserStore.dataCached &&
      !BrandStore.dataCached
    ) {
      await Promise.all([
        UserStore.getUsers(alert, false),
        BrandStore.getBrands(alert),
      ]);
    } else if (
      DealStore.dataCached &&
      BrandStore.dataCached &&
      !UserStore.dataCached
    ) {
      await Promise.all([UserStore.getUsers(alert, false)]);
    } else if (
      DealStore.dataCached &&
      !BrandStore.dataCached &&
      UserStore.dataCached
    ) {
      await Promise.all([BrandStore.getBrands(alert)]);
    }

    setCkLoading(false);
    setLoading(false);
    setCokitchens(DealStore.deals);
    setCurrentCokitchen({
      deals: sortAllDeals(),
      name: "All users (all locations)",
    });
    setDeals(DealStore.deals);
  }, []);
  // Collect deals for all cokitchens into a single array
  const sortAllDeals = () => {
    let allCKsCopy = [];
    let allCKs = DealStore.deals;
    let i, j, k;
    for (i = 0; i < allCKs.length; i++) {
      if (allCKs && allCKs[i] && allCKs[i].deals && allCKs[i].deals)
        for (j = 0; j < allCKs[i].deals.length; j++) {
          allCKsCopy.push(allCKs[i].deals[j]);
        }
    }
    return allCKsCopy;
  };
  // Set brand options based on current cokitchen
  useEffect(() => {
    let curr =
      (currentCokitchen?.deals &&
        currentCokitchen?.deals[0] &&
        currentCokitchen?.deals[0].deals) ||
      currentCokitchen?.deals ||
      [];
    setCurrentDeals(curr.filter(item=> item?.item_type==='FOOD' && new Date (item?.to_expire_date) <  new Date()) );
  }, [currentCokitchen]);

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const getTotal = (items) => {
    let total = items.reduce(function (acc, curr) {
      return (
        acc +
        (curr?.calculated_order?.prev_price &&
          parseFloat(curr.calculated_order.prev_price) -
            parseFloat(curr.calculated_order.total_amount))
      );
    }, 0);
    return numberWithCommas(total);
  };

  return (
    <main className="main_container">
      <div className="main_container_content">
        <Header />

        <section className="main_section">
          <div className="top_fixed">
            <Tab deals="active_nav_item" />
            <div className="border_bottom_thick">
              <div className="flex_row_between title_bar">
                <div className="flex_row ">
                  <div className="mr_15" onClick={()=>history.push(DEALS_DISCOUNTS)}>
                <ArrowBack width="17px" height="17px" fill="#FF0000" />
                </div>
                <h6 className="bold_24px">Inactive deals & discounts</h6>
              
                </div>
              </div>
              <div className="flex_row_between mobile_visible title_bar">
                <Button
                  withIcon
                  icon={<PlusIcon width="13px" height="13px" fill="#2699FB" />}
                  text="New discount & post"
                  onClick={() => history.push("add-deals/discount_post")}
                />
              </div>
            </div>
          </div>

          <div className="top_section">
            <div className="mobile_visible px_50 flex_row_between">
              {pageWidth <= 628 ? (
                <button
                  className="mr_25 cursor_pointer"
                  onClick={() => setShowSide(!showSide)}
                >
                  <LocationIcon
                    fill={"#FF0000"}
                    width="18px"
                    height="18px"
                    className={showSide ? "rotate_90 ease" : "no_rotate ease"}
                  />
                </button>
              ) : null}
            
            </div>
          </div>

          <section className="content_section">
            {pageWidth > 628 || showSide ? (
              <aside className={`slide_in`}>
                {ckLoading ? (
                  <div className="flex_column_left">
                    <p className="mb_12">Cokitchens loading...</p>
                    <Loader
                      type="ThreeDots"
                      color="#ff000060"
                      height={10}
                      width={50}
                    />
                  </div>
                ) : null}
                {!ckLoading ? (
                  <NavItem
                    navText="All users (all locations)"
                    onClick={() =>
                      setCurrentCokitchen({
                        deals: sortAllDeals(),
                        name: "All users (all locations)",
                      })
                    }
                    activeItem={
                      currentCokitchen.name === "All users (all locations)"
                    }
                  />
                ) : null}
                {cokitchens.map((item, i) => {
                  return (
                    <NavItem
                      navText={item.name}
                      key={i + "name"}
                      onClick={() => setCurrentCokitchen(item)}
                      activeItem={currentCokitchen.name === item.name}
                    />
                  );
                })}
              </aside>
            ) : null}
            <section className="cards_container grid_third_two px_40 easein-light">
              {!loading && !currentDeals[0] ? (
                <label className="bold_24px gray">Deals are empty</label>
              ) : null}

              {loading
                ? currentDealsPlaceHolder.map((item, i) => {
                    return <DealCard key={i + "item"} loading={true} />;
                  })
                : currentDeals?.map((item, i) => {
                    let valueType =
                      item && item.deal_value_type && item.deal_value_type.name;
                    let percentage =
                      valueType && valueType.toLowerCase() === "percentage"
                        ? parseFloat(item.rate) * 100 + " " + "%"
                        : "";
                    let img =
                      item &&
                      item.images &&
                      item.images[0].replace(/[']+/g, "");

                    if (item.id !== "1acbe310-1360-4826-a27b-5705b99c32f0") {
                      return (
                        <DealCard
                          id={item.id}
                          key={i + "item"}
                          loading={loading}
                          src={img}
                          title={item.title}
                          subtitle={item.heading}
                          locations={item.locations}
                          clicks={item.clicks}
                          badgeTime={moment(
                            item.to_start_date + " " + item.to_start_time
                          ).fromNow()}
                          logo_src={img}
                          expiresDate={
                            item &&
                            moment(item.to_expire_date).format("DD/MM/YY")
                          }
                          price={
                            item && item.user_deals && getTotal(item.user_deals)
                          }
                          expiresTime={
                            item &&
                            item.to_expire_time &&
                            moment(
                              item.to_expire_date + " " + item.to_expire_time
                            ).format("LT")
                          }
                          frequency={item?.user_deals?.length}
                          name={
                            (item &&
                              item.brands &&
                              item.brands[0] &&
                              item.brands[0].name + "...") ||
                            "All brands"
                          }
                          code={
                            item &&
                            item.discount_code &&
                            item.discount_code.toUpperCase()
                          }
                        />
                      );
                    } else {
                      return null;
                    }
                  })}
            </section>
            <section className="desktop_visible">
             
            </section>
          </section>
        </section>
      </div>
    </main>
  );
};
export default withAlert()(InactiveDeals);
