import { observable, runInAction, action } from "mobx";
import dataService from "../../services/data.service";
import {
  GET_COKITCHENS_URL, CREATE_NOTIFICATION,
  COKITCHENS_URL,
  GET_SCHEDULE_NOTIFICATION,
  CREATE_SCHEDULE_NOTIFICATION,
  DELETE_SCHEDULE_NOTIFICATION,
  UPDATE_SCHEDULE_NOTIFICATION,
  GET_COKITCHEN_POLYGON_ID
} from "../../helpers/urls";
import errorHandler from "../../helpers/errorHandler";
export class CommonStore {
  @observable cokitchens = [];
  @observable scheduledNotifications = [];
  @observable brands = [];
  @observable coordinatesDetails = [];

  @action updateCokitchen = (item) => {
    this.cokitchens = item;
  };
  @observable dataCached = false;

  getCokitchens = async (alert, shouldAlert) => {
    try {
      let response = await dataService.getInternalData(GET_COKITCHENS_URL);

      runInAction(() => {
        let res = response.data && response.data.cokitchens;
        let brandRes = response.data && response.data.brands;
        let i;
        let options = [];

        for (i = 0; i < res.length; i++) {
          options.push({ ...res[i], label: res[i].name, value: res[i].id });
        }
        this.cokitchens = options;
        this.brands = brandRes;
        shouldAlert &&
          alert.show("Cokitchens loaded successfully!", {
            type: "success",
          });

        this.dataCached = true;
      });
      return response;
    } catch (error) {
      errorHandler(error, alert);
    }
  };

  postPosts = async (alert, data) => {
    try {
      let response = await dataService.postAuthData(CREATE_NOTIFICATION, data);
      let res;
      runInAction(() => {
        res = response

        if (res) {
          alert.show("Notification sent successfully!", {
            type: "success",
          });

          this.dataCached = true;
        }
      });
      return res;
    } catch (error) {
      errorHandler(error, alert);
    }
  };

  postCokitchens = async (alert, data) => {
    try {
      let response = await dataService.postAuthData(COKITCHENS_URL, data);
      let res;
      runInAction(() => {
        res = response

        if (res) {
          alert.show("Cokitchen created successfully!", {
            type: "success",
          });
        }
      });
      return res;
    } catch (error) {
      errorHandler(error, alert);
    }
  };
  getScheduledNotifications = async (alert) => {
    try {

      let response = await dataService.postAuthData(GET_SCHEDULE_NOTIFICATION);

      runInAction(() => {
        let res;
        res = response && response.data;
        this.scheduledNotifications = res
        if (res) {
          alert.show("Notification gotten successfully!", {
            type: "success",
          });
        }
      });

    } catch (error) {
      errorHandler(error, alert);
    }
  };
  createScheduleNotification = async (alert, data) => {
    try {
      let response = await dataService.postAuthData(CREATE_SCHEDULE_NOTIFICATION, data);

      runInAction(() => {
        let res;
        res = response && response.data;
        this.newNotification = res
        if (res) {
          alert.show("Notification created successfully!", {
            type: "success",
          });
        }
      });

    } catch (error) {
      errorHandler(error, alert);
    }
  };

  deleteScheduleNotification = async (alert, data) => {
    try {
      let response = await dataService.postAuthData(DELETE_SCHEDULE_NOTIFICATION, data);

      runInAction(() => {
        let res;
        res = response && response.data;

        if (res) {
          alert.show("Notification deleted successfully!", {
            type: "success",
          });
        }
      });

    } catch (error) {
      errorHandler(error, alert);
    }
  };
  updateScheduleNotification = async (alert, data) => {
    try {
      let response = await dataService.postAuthData(UPDATE_SCHEDULE_NOTIFICATION, data);

      runInAction(() => {
        let res;
        res = response && response.data;

        if (res) {
          alert.show("Notification updated successfully!", {
            type: "success",
          });
        }
      });

    } catch (error) {
      errorHandler(error, alert);
    }
  };


  updateCokitchens = async (alert, data) => {
    try {
      let response = await dataService.putAuthData(COKITCHENS_URL, data);
      let res;
      runInAction(() => {
        res = response && response.data;

        if (res) {
          alert.show("Cokitchen created successfully!", {
            type: "success",
          });
        }
      });
      return res;
    } catch (error) {
      errorHandler(error, alert);
    }
  };


  getCokitchenPolygonId = async (alert, data) => {
    try {
      let res = await dataService.postAuthData(
        GET_COKITCHEN_POLYGON_ID,
        data
      );
      this.coordinatesDetails = res?.data;
    } catch (error) {
      errorHandler(error, alert);
    }
  };

}
export default new CommonStore();
