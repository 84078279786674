import React, { useRef } from "react";
import { Button } from "../buttons";
import Image from "../../assets/images/image.png";
import "./styles.scss";
import imageCompression from "browser-image-compression";
import { useState } from "react";


const ImageFile = ({ onChange, src, onClick, text, className }) => {
 const [compressedImage, setCompressedImage] = useState("");
  const fileRef = useRef();

  
   async function handleImageUpload(src) {
     const imageFile = src.files[0];
     console.log("originalFile instanceof Blob", imageFile instanceof Blob); // true
     console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

     const options = {
       maxWidthOrHeight: 700,
       useWebWorker: true,
     };
     try {
       const compressedFile = await imageCompression(imageFile, options);
       console.log(
         "compressedFile instanceof Blob",
         compressedFile instanceof Blob
       ); // true
       console.log(
         `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
       ); // smaller than maxSizeMB

      // await uploadToServer(compressedFile); // write your own logic
      setCompressedImage(compressedFile);
     } catch (error) {
       console.log(error);
     }
     return(compressedImage)
   }
 
 

  return (
    <div className={`image_file ${className}`}>
      <Button
        text={text}
        isBlue
        type="button"
        onClick={() => {
          fileRef.current.click();
        }}
        className="mb_25"
      />
      <input
        type="file"
        className="multi file-upload-input with-preview"
        //    maxLength={3}
        accept=".jpg, .jpeg, .png, .svg"
        onChange={onChange}
        ref={fileRef}
      />
      <img
        alt="meal_allergy_metadata"
        src={(src && src) || Image}
      />
    </div>
  );
};
export default ImageFile;
