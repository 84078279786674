import React from "react";
import { withRouter } from "react-router-dom";
import Cookies from "js-cookie";

const logout = () => {
  // Cookies.remove("token");
  // Cookies.remove("id");
  // Cookies.remove("email");
  // Cookies.remove("first_name");
  // Cookies.remove("last_name");
  // Cookies.remove("role");
};

const errorHandler = (error, alert, type) => {
  if ( error?.response?.status === 401) {
    logout();
  }
  const errMsg =
    error && (error.response || error.message || error.toString() || error);
  let errorMsg = (errMsg && errMsg.data && errMsg.data.message) || errMsg;

if (typeof errorMsg !== "string") {
    alert.show("An error has occured!!", {
      type: "error",
    });
  } else {
    if(errorMsg?.toLowerCase() === "Network Error".toLowerCase()&& type==="is_bulk")
    {
      alert.show("Request processing. Meals are being created...", {
        type: "info",
      });
    }
   else{ alert.show(errorMsg, {
      type: "error",
    });}
  }

  console.log("Error handler", errMsg);
};
export default errorHandler;
