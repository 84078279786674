import axios from "axios";
import Cookies from "js-cookie";
import { withRouter } from "react-router-dom";

const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;
const LOGIN_URL = "internal/auth/login-marketing";
const SIGNUP_URL = "internal/auth/register-marketing";

class AuthService {
  /***********************************Login********************* */
  login(data) {
    return axios
      .post(API_URL + LOGIN_URL, data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
        },
      })
      .then((response) => {
        if (response.data) {
          let res = response.data;
          Cookies.set("token", res.token);
          Cookies.set("id", res.id);
          Cookies.set("email", res.email);
          Cookies.set("first_name", res.first_name);
          Cookies.set("last_name", res.last_name);
          Cookies.set("role", res.role);
        }
        return response;
      });
  }

  /***********************************Signup********************* */
  signup(data) {
    return axios
      .post(API_URL + SIGNUP_URL, data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
        },
      })
      .then((response) => {
        if (response.data) {
          let res = response.data;
          Cookies.set("token", res.token);
          Cookies.set("id", res.id);
          Cookies.set("email", res.email);
          Cookies.set("first_name", res.first_name);
          Cookies.set("last_name", res.last_name);
          Cookies.set("role", res.role);
        }
        return response;
      });
  }
}

export default withRouter(new AuthService());
