import React from "react";
import { Route, Redirect } from "react-router-dom";
import { LOGIN } from "./router";
import Cookies from "js-cookie";
const AuthRoute = ({ component: Component, authenticated, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        Cookies.get("token") ? (
          <Route render={() => <Component {...props} />} />
        ) : (
          <Redirect to={LOGIN} />
        )
      }
    />
  );
};
export default AuthRoute;
