import React from "react";
import { LocationIcon } from "../../assets/icons/icons";
import "./styles.scss";

const sideNavItem = ({ activeItem, navText, onClick }) => {
  return (
    <div
      className="side_nav_item flex_row_align_center cursor_pointer mb_35"
      onClick={onClick}
    >
      <span className="mr_25">
        <LocationIcon
          fill={activeItem ? "#FF0000" : "#ACACAC"}
          width="16px"
          height="16px"
        />
      </span>
      <a
        className={`${activeItem ? "bold_18_px" : "gray_label_sec"}`}
        href={"#/" + navText}
      >
        {navText}
      </a>
    </div>
  );
};

export default sideNavItem;
