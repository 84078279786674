import React, { useState, useEffect, useRef } from 'react';
import './styles.scss';
import Header from '../../components/header';
import Tab from '../../components/tabs';
import { Button } from '../../components/buttons';
import PostCard from '../../components/postCard';
import NavItem from '../../components/sideNavItem';
import { PlusIcon, LocationIcon } from '../../assets/icons/icons';
import { useHistory } from 'react-router';
import Modal from '../../components/modal';
import { ReactSortable } from 'react-sortablejs';
import HomeStore from '../../stores/dataStore/homeStore';
import CommonStore from '../../stores/dataStore/commonStore';
import axios from 'axios';
import ImageFile from '../../components/imageFile';
import TextEditor from '../../components/textEditor';
import Loader from 'react-loader-spinner';
import Select from '../../components/select';
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import Input from '../../components/inputs';
import IconInput from '../../components/inputs/iconInput';
import { Clock, DateIcon } from '../../assets/icons/icons';
import { withAlert } from 'react-alert';
import Cookies from 'js-cookie';
import moment from 'moment';
import Prompt from '../../components/modal/prompt';
import dealStore from '../../stores/dataStore/dealStore';
import { INACTIVE_POSTS } from '../../router/router';
import dataService from '../../services/data.service';

const HomePage = ({ alert }) => {
  const [posts, setPosts] = useState([]);
  const postsPlaceHolder = [{}, {}, {}, {}, {}, {}, {}, {}, {}];

  const [ckLoading, setCkLoading] = useState(false);

  const [cokitchens, setCokitchens] = useState([]);
  const [polygons, setPolygons] = useState([]);
  const [polygonsLoading, setPolygonsLoading] = useState(false);
  const [currentCokitchen, setCurrentCokitchen] = useState({});
  const [currentPost, setCurrentPost] = useState([]);
  const [selectedPosts, setSelectedPosts] = useState([]);
  const [showSide, setShowSide] = useState(true);
  const [showAddForm, setShowAddForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [pageWidth, setPageWidth] = useState('');
  const [postDragDisabled, setPostDragDisabled] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const [postId, setPostId] = useState('');

  const [postsForm, setPostsForm] = useState({
    cokitchen_id: '',
    title: '',
    heading: '',
    to_expire_date: '',
    to_expire_time: '',
    to_start_date: '',
    to_start_time: '',
  });
  const [image, setImage] = useState(null);
  const [imageDisplay, setImageDisplay] = useState(null);
  const [textEditorState, setTextEditorState] = useState('');
  const postRef = useRef(null);

  const defaultPolygonIds =
    typeof currentPost?.polygon_ids === 'string'
      ? JSON.parse(currentPost.polygon_ids)
      : currentPost?.polygon_ids ?? [];

  const goToPosts = () => {
    postRef.current.scrollIntoView();
  };
  const history = useHistory();
  const onCardSelect = (i) => {
    let newArr = [...selectedPosts, i];
    if (selectedPosts.includes(i)) {
      newArr = newArr.filter((day) => day !== i);
    }
    setSelectedPosts(newArr);
  };

  const loadPolygons = async (cokitchen_id) => {
    try {
      setPolygonsLoading(true);
      const res = await dataService.getInternalData(
        `internal/cokitchen/polygons/${cokitchen_id}`
      );
      setPolygons(res.data.data);
    } catch (err) {
      setPolygons([]);
    } finally {
      setPolygonsLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  useEffect(() => {
    if (currentCokitchen?.id) loadPolygons(currentCokitchen.id);
  }, [currentCokitchen?.id]);

  useEffect(() => {
    setPageWidth(window.innerWidth);

    if (pageWidth <= 628) {
      setShowSide(false);
    } else {
      setShowSide(true);
    }

    // Set text editor state
    setTextEditorState(
      EditorState.createWithContent(
        ContentState.createFromBlockArray(convertFromHTML(''))
      )
    );
  }, [pageWidth]);

  useEffect(() => {
    const handleWindowResize = () => {
      setPageWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, [pageWidth]);

  // load posts from store
  useEffect(async () => {
    setCkLoading(true);
    setLoading(true);
    if (!HomeStore.dataCached) {
      await HomeStore.getPosts(alert);
      setCokitchens(HomeStore.posts);
    }

    setCkLoading(false);
    setLoading(false);

    setCokitchens(HomeStore.posts);
    setCurrentCokitchen(
      HomeStore?.posts
        .map((item) => {
          return item;
        })
        .find((item) => item.id === '85c7d173-56ed-44c8-8335-ef0eecabf454')
    );
    setPosts([]);
  }, []);

  // Set posts based on current cokitchen
  useEffect(() => {
    let firstArr = currentCokitchen?.cokitchen_home_page_posts?.filter(
      (item) => {
        return item.item_type !== 'SHOP';
      }
    );
    setPostId(firstArr?.map((item) => item.id));
    let postArray = firstArr?.map((item) => {
      return item.posts;
    });
    let filteredPosts = postArray?.[0]?.filter(
      (item) =>
        new Date(moment(item?.to_expire_date).add(1, 'days')) > new Date()
    );
    setPosts(filteredPosts || []);
  }, [currentCokitchen, cokitchens]);

  // Reset add form
  const resetForm = () => {};

  const postsArrangementUpdate = async () => {
    const { updatePostsArrangement, getPosts } = HomeStore;
    setLoading(true);
    let i;
    let data = [];
    for (i = 0; i < posts.length; i++) {
      data.push({});
    }
    await updatePostsArrangement(alert, {
      posts: posts,
      cokitchen_id: currentCokitchen.id,
    });
    await getPosts(alert);
    setLoading(false);
  };

  // Submit add form
  const formSubmit = async () => {
    const { postPosts, updatePost } = HomeStore;
    const { getCokitchens } = CommonStore;
    let body = showAddForm ? postsForm : currentPost;
    if (showAddForm && !image) {
      alert.show(`Please upload an image for this post!`, {
        type: 'info',
      });
    } else if (showUpdateForm && !currentPost.images) {
      alert.show(`Please upload an image for this post!`, {
        type: 'info',
      });
    } else if (body.to_start_date && !body.to_start_time) {
      alert.show(`Start date cannot be empty when start time is filled!`, {
        type: 'info',
      });
    } else if (body.to_expire_date && !body.to_expire_time) {
      alert.show(`End date cannot be empty when end time is filled!`, {
        type: 'info',
      });
    } else {
      try {
        setSubmitting(true);
        let urlRes, url;
        if (image) {
          const formData = new FormData();
          formData.append('file', image);
          const token = Cookies.get('token');

          const axiosConfig = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
          urlRes = await axios.post(
            'https://file-api.getfoodcourt.com/upload',
            formData,
            axiosConfig
          );
        }
        if (urlRes?.data?.url) {
          url = urlRes?.data?.url;
          setImageDisplay(url);
        }

        if (showAddForm) {
          body.to_expire_date = moment(
            new Date(postsForm.to_expire_date)
          ).format('YYYY-MM-DD');

          body.to_start_date = moment(new Date(postsForm.to_start_date)).format(
            'YYYY-MM-DD'
          );

          body.to_expire_time = moment(
            new Date(postsForm.to_expire_time)
          ).format('HH:mm:ss');
          body.to_start_time = moment(new Date(postsForm.to_start_time)).format(
            'HH:mm:ss'
          );
          body.item_type = 'FOOD';
        } else if (showUpdateForm) {
          body.to_expire_date = moment(
            new Date(currentPost.to_expire_date)
          ).format('YYYY-MM-DD');

          body.to_start_date = moment(
            new Date(currentPost.to_start_date)
          ).format('YYYY-MM-DD');

          body.to_expire_time =
            moment(new Date(currentPost.to_expire_time)).format('HH:mm:ss') ==
            'Invalid date'
              ? currentPost.to_expire_time
              : moment(new Date(currentPost.to_expire_time)).format('HH:mm:ss');
          body.to_start_time =
            moment(new Date(currentPost.to_start_time)).format('HH:mm:ss') ==
            'Invalid date'
              ? currentPost.to_start_time
              : moment(new Date(currentPost.to_start_time)).format('HH:mm:ss');
        }
        body = JSON.parse(JSON.stringify(body));

        if (typeof body.images === 'string') {
          let img = JSON.parse(body?.images)[0];

          body.images = [img];
        }
        if (url) {
          body.images = [url];
        }
        body.body = JSON.stringify(
          draftToHtml(convertToRaw(textEditorState.getCurrentContent()))
        );

        if (showAddForm) {
          await setPostsForm({
            ...postsForm,
            images: [url],
            body: JSON.stringify(
              draftToHtml(convertToRaw(textEditorState.getCurrentContent()))
            ),
          });
        }

        delete body.chosen;
        delete body.id;
        delete body.updated_at;
        delete body.created_at;
        delete body.deal_id;
        delete body.active;
        delete body.selected;
        showUpdateForm && delete body.cokitchen_id;
        body.to_expire_time === 'Invalid date' && delete body.to_expire_time;
        body.to_start_time === 'Invalid date' && delete body.to_start_time;
        body.to_expire_date === 'Invalid date' && delete body.to_expire_date;
        body.to_start_date === 'Invalid date' && delete body.to_start_date;
        if (typeof body?.polygon_ids === 'string')
          body.polygon_ids = JSON.parse(body.polygon_ids);
        let res;
        if (showAddForm) {
          body.cokitchen_id = currentCokitchen.id;
          res = await postPosts(alert, body);
        } else if (showUpdateForm) {
          res = await updatePost(alert, body, currentPost?.id);
        }

        if (res) {
          setSubmitting(false);
          setShowAddForm(false);
          setShowUpdateForm(false);
          alert.show('Fetching updates...', {
            type: 'info',
          });
          await getCokitchens(alert, false);
        }
      } catch (error) {
        const errMsg =
          error &&
          (error.response || error.message || error.toString() || error);
        alert.show(
          (errMsg && errMsg.data && errMsg.data.message) ||
            errMsg ||
            'An error has occured!!',
          {
            type: 'error',
          }
        );
      } finally {
        setSubmitting(false);
        resetForm();

        setCokitchens(HomeStore.posts);
        setCurrentCokitchen(
          HomeStore.posts.find(
            (item) => item.id === '85c7d173-56ed-44c8-8335-ef0eecabf454'
          )
        );
      }
    }
  };

  // To delete posts
  const deletePost = async () => {
    const { deletePosts, getPosts } = HomeStore;
    const { deleteDeals } = dealStore;

    setDeleting(true);
    if (currentPost?.deal_id) {
      await deleteDeals(currentPost?.deal_id, alert, false);
    } else {
      await deletePosts(currentPost?.id, alert);
    }
    setDeleting(false);
    setShowPrompt(false);
    setShowUpdateForm(false);
    alert.show('Fetching updates...', {
      type: 'info',
    });
    await getPosts(alert, false);
    setCokitchens(HomeStore.posts);
    setCurrentCokitchen(
      HomeStore?.posts
        .map((item) => {
          return item;
        })
        .find((item) => item.id === '85c7d173-56ed-44c8-8335-ef0eecabf454')
    );
  };

  const postArrangementUpdate = async () => {
    const { updatePostsArrangement, getPosts } = HomeStore;
    const { getCokitchens } = CommonStore;
    if (currentCokitchen.name === 'All users (all locations)') {
      alert.show(
        'Arrangements can only be saved for specific cokitchens. Select any cokitchen on the left to save arrangements',
        {
          type: 'info',
        }
      );
    } else {
      setLoading(true);
      let i;
      let data = [];
      for (i = 0; i < posts.length; i++) {
        data.push({
          title: posts[i].title,
          id: posts[i].id,
          heading: posts[i].heading,
          body: posts[i].body,
          images: posts[i].images,
          to_expire_date: posts[i].to_expire_date,
          to_expire_time: posts[i].to_expire_time,
          to_start_date: posts[i].to_start_date,
          to_start_time: posts[i].to_start_time,
        });
      }

      await updatePostsArrangement(alert, {
        posts: data,
        cokitchen_id: currentCokitchen.id,
        id: postId[0],
      });
      setLoading(false);
      await getCokitchens(alert, false);
      setCokitchens(HomeStore.posts);
      setCurrentCokitchen(
        HomeStore?.posts
          .map((item) => {
            return item;
          })
          .find((item) => item.id === '85c7d173-56ed-44c8-8335-ef0eecabf454')
      );
      setPostDragDisabled(false);
    }
  };

  const onImageChange = async (e) => {
    setImage(e);
    e && setImageDisplay(URL.createObjectURL(e));
  };

  const validStartDate = (current) => {
    return current.isAfter(moment().subtract(1, 'day'));
  };

  const validEndDate = (current) => {
    return current.isAfter(
      moment(postsForm?.to_start_date || '').subtract(1, 'day')
    );
  };

  const validEndDateAlt = (current) => {
    return current.isAfter(
      moment(currentPost?.to_start_date || '').subtract(1, 'day')
    );
  };
  return (
    <main className='main_container'>
      <div className='main_container_content'>
        <Header />

        <section className='main_section'>
          <div className='top_fixed'>
            <Tab home='active_nav_item nav_item_first' />
            <div className='border_bottom_thick'>
              <div className='flex_row_between title_bar'>
                <h6 className='bold_24px'>
                  <span
                    className='cursor_pointer underlined'
                    onClick={goToPosts}
                  >
                    Active posts ({posts.length})
                  </span>{' '}
                  {/* |{" "}
                  <span
                    className="cursor_pointer underlined"
                    onClick={goToPendingPosts}
                  >
                    Pending posts ({pendingPosts.length})
                  </span> */}
                </h6>
                <div className='flex_row_center'>
                  <Button
                    text='Inactive Posts'
                    className='desktop_visible  mr_15'
                    onClick={() => history.push(INACTIVE_POSTS)}
                  />
                  <Button
                    withIcon
                    icon={
                      <PlusIcon width='13px' height='13px' fill='#2699FB' />
                    }
                    text='New Post'
                    onClick={() => {
                      setPostsForm({
                        cokitchen_id: currentCokitchen?.id || '',
                        title: '',
                        heading: '',
                        to_expire_date: '',
                        to_expire_time: '',
                        to_start_date: '',
                        to_start_time: '',
                      });
                      setTextEditorState(
                        EditorState.createWithContent(
                          ContentState.createFromBlockArray(convertFromHTML(''))
                        )
                      );
                      setImageDisplay('');
                      setImage('');
                      setShowAddForm(!showAddForm);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='top_section'>
            <div className='mobile_visible px_50 flex_row_between'>
              {pageWidth <= 628 ? (
                <button
                  className='mr_25 cursor_pointer'
                  onClick={() => setShowSide(!showSide)}
                >
                  <LocationIcon
                    fill={'#FF0000'}
                    width='18px'
                    height='18px'
                    className={showSide ? 'rotate_90 ease' : 'no_rotate ease'}
                  />
                </button>
              ) : null}
              <section className='flex_column_right'>
                <Button
                  text={postDragDisabled ? 'Re-arrange posts' : 'Edit Post'}
                  className='mt_35'
                  onClick={() => {
                    setPostDragDisabled(!postDragDisabled);
                  }}
                  isBlue={!postDragDisabled}
                  isRed={postDragDisabled}
                />
                {!postDragDisabled && (
                  <Button
                    text='Save changes'
                    onClick={postArrangementUpdate}
                    disabled={loading}
                    className='mt_35'
                  />
                )}
              </section>
            </div>
          </div>
          <section className='content_section'>
            {pageWidth > 628 || showSide ? (
              <aside className={`slide_in`}>
                {ckLoading ? (
                  <div className='flex_column_left'>
                    <p className='mb_12'>Cokitchens loading...</p>
                    <Loader
                      type='ThreeDots'
                      color='#ff000060'
                      height={10}
                      width={50}
                    />
                  </div>
                ) : null}

                {cokitchens.map((item, i) => {
                  return (
                    <NavItem
                      navText={item.name}
                      key={i + 'name'}
                      onClick={() => setCurrentCokitchen(item)}
                      activeItem={currentCokitchen?.name === item?.name}
                    />
                  );
                })}
              </aside>
            ) : null}

            <section className='cards_container px_50 flex_column_left'>
              <h6 className='bold_24px mb_15' ref={postRef}>
                Active home page posts ({posts.length})
              </h6>
              <div className='width_full'>
                {loading || polygonsLoading ? (
                  <div className='grid_third pb_30 mb_35 '>
                    {postsPlaceHolder.map((item, i) => {
                      return <PostCard key={i + 'item'} loading={true} />;
                    })}
                  </div>
                ) : (
                  <ReactSortable
                    list={posts}
                    setList={setPosts}
                    className='grid_third pb_30 mb_35 '
                    animation={300}
                    delayOnTouchStart={true}
                    delay={1.5}
                  >
                    {posts.map((item, i) => {
                      let imgSrc =
                        item &&
                        item.images &&
                        typeof item.images === 'string' &&
                        JSON.parse(item.images) &&
                        JSON.parse(item.images)[0];

                      let locs =
                        (currentCokitchen &&
                          currentCokitchen.cokitchen_polygons &&
                          currentCokitchen.cokitchen_polygons.length) ||
                        1;

                      return (
                        <PostCard
                          key={item.id + item.title}
                          loading={loading}
                          src={imgSrc}
                          title={item.title}
                          subtitle={item.heading}
                          locations={`${locs} ${
                            locs === 1 ? 'location' : 'locations'
                          }`}
                          clicks={item.clicks}
                          time={item.time}
                          selected={selectedPosts.includes(i)}
                          onClick={() => onCardSelect(i)}
                          onEditClick={() => {
                            setCurrentPost({
                              ...item,
                            });
                            setTextEditorState(
                              EditorState.createWithContent(
                                ContentState.createFromBlockArray(
                                  convertFromHTML(JSON.parse(item?.body || ''))
                                )
                              )
                            );
                            setImageDisplay('');
                            setShowUpdateForm(!showUpdateForm);
                          }}
                          expire={item.expire}
                        />
                      );
                    })}
                  </ReactSortable>
                )}
              </div>
            </section>
            <section className='desktop_visible'>
              <Button
                text={postDragDisabled ? 'Re-arrange posts' : 'Edit Post'}
                className='mt_35'
                onClick={() => {
                  setPostDragDisabled(!postDragDisabled);
                }}
                isBlue={!postDragDisabled}
                isRed={postDragDisabled}
              />
              {!postDragDisabled && (
                <Button
                  text='Save changes'
                  onClick={postArrangementUpdate}
                  disabled={loading}
                  className='mt_35'
                />
              )}
            </section>
          </section>
        </section>
      </div>

      {/* Add post modal */}
      <Modal
        showModal={showAddForm}
        closeModal={() => setShowAddForm(!showAddForm)}
        loading={submitting}
        onSubmit={(e) => {
          e.preventDefault();
          formSubmit();
        }}
        saveText={submitting ? 'Creating post...' : 'Create Post'}
        title='Add New Post'
        customContent={
          <div>
            <Input
              placeholder='Enter post heading'
              value={postsForm.heading}
              onChange={(e) => setPostsForm({ ...postsForm, heading: e })}
              required
              containerClass='mb_15 '
              withLabel
              label='Post heading'
            />
            <Input
              placeholder='Enter post title'
              value={postsForm.title}
              onChange={(e) => setPostsForm({ ...postsForm, title: e })}
              required
              containerClass='mb_15'
              withLabel
              label='Post title'
            />
            <div className='mb_35'>
              <TextEditor
                placeholder='Deal post body'
                editorState={textEditorState}
                onEditorStateChange={(e) => {
                  setTextEditorState(e);
                }}
              />
            </div>
            <ImageFile
              onChange={(e) => {
                onImageChange(e.target.files[0]);
              }}
              src={imageDisplay}
              text='Upload Image'
            />

            <div className='flex_row_top tab_column'>
              <div className='mr_15 width_full'>
                <IconInput
                  containerClass='mb_15 width_full'
                  withLabel
                  isDate
                  label='Start date:'
                  dateValue={postsForm.to_start_date}
                  onDateChange={(e) =>
                    setPostsForm({ ...postsForm, to_start_date: new Date(e) })
                  }
                  icon={<DateIcon width='11px' height='11px' />}
                  dateFormat='DD/MM/YYYY'
                  isValidDate={validStartDate}
                />
              </div>

              <div className='ml_15 width_full'>
                <IconInput
                  containerClass='mb_15 width_full'
                  withLabel
                  isDate
                  label='Start time:'
                  dateValue={postsForm.to_start_time}
                  onDateChange={(e) =>
                    setPostsForm({ ...postsForm, to_start_time: new Date(e) })
                  }
                  icon={<Clock width='11px' height='11px' fill='' />}
                  dateFormat={false}
                />
              </div>
            </div>

            <div className='flex_row_top tab_column'>
              <div className='mr_15 width_full'>
                <IconInput
                  containerClass='mb_15 width_full'
                  withLabel
                  isDate
                  label='Expiry date:'
                  dateValue={postsForm.to_expire_date}
                  onDateChange={(e) =>
                    setPostsForm({ ...postsForm, to_expire_date: new Date(e) })
                  }
                  icon={<DateIcon width='11px' height='11px' />}
                  dateFormat='DD/MM/YYYY'
                  isValidDate={validEndDate}
                />
              </div>
              <div className='ml_15 width_full'>
                <IconInput
                  containerClass='mb_15 width_full'
                  withLabel
                  isDate
                  label='Expiry time:'
                  dateValue={postsForm.to_expire_time}
                  onDateChange={(e) =>
                    setPostsForm({ ...postsForm, to_expire_time: new Date(e) })
                  }
                  icon={<Clock width='11px' height='11px' fill='' />}
                  dateFormat={false}
                />
              </div>
            </div>

            <Select
              isMulti
              placeholder='Select polygon(s) to apply post'
              defaultValue={[]}
              handleChange={(e) =>
                setPostsForm({ ...postsForm, polygon_ids: e.map((d) => d.id) })
              }
              containerClass='width_full '
              options={polygons}
              name='name'
              id='id'
            />
          </div>
        }
      />

      {/* Update post modal */}
      <Modal
        showModal={showUpdateForm}
        closeModal={() => setShowUpdateForm(!showUpdateForm)}
        loading={submitting}
        onSubmit={(e) => {
          e.preventDefault();
          formSubmit();
        }}
        withDel
        deleteText={deleting ? 'Deleting post...' : 'Delete Post'}
        delClick={(e) => {
          e.preventDefault();
          setShowPrompt(true);
        }}
        saveText={submitting ? 'Updating post...' : 'Update Post'}
        title={`Edit ${currentPost && currentPost.title}`}
        customContent={
          <div>
            <Input
              placeholder='Enter post title'
              value={currentPost.title}
              onChange={(e) => setCurrentPost({ ...currentPost, title: e })}
              required
              containerClass='mb_15'
              withLabel
              label='Post title'
            />
            <Input
              placeholder='Enter post heading'
              value={currentPost.heading}
              onChange={(e) => setCurrentPost({ ...currentPost, heading: e })}
              required
              containerClass='mb_15 '
              withLabel
              label='Post heading'
            />
            <div className='mb_35'>
              <TextEditor
                placeholder='Deal post body'
                // editorState={currentPost.body}
                editorState={textEditorState}
                onEditorStateChange={(e) => {
                  setTextEditorState(e);
                }}
              />
            </div>
            <ImageFile
              onChange={(e) => {
                onImageChange(e.target.files[0]);
              }}
              src={
                (imageDisplay && imageDisplay) ||
                (currentPost?.images &&
                  currentPost.images &&
                  currentPost.images[0])
              }
              text='Upload Image'
            />
            {/* && currentPost.images.replace(/[/,[,]]+/g, "")  */}
            <div className='flex_row_top tab_column'>
              <div className='mr_15 width_full'>
                <IconInput
                  containerClass='mb_15 width_full'
                  withLabel
                  isDate
                  label='Start date:'
                  dateValue={currentPost.to_start_date}
                  onDateChange={(e) =>
                    setCurrentPost({
                      ...currentPost,
                      to_start_date: new Date(e),
                    })
                  }
                  icon={<DateIcon width='11px' height='11px' />}
                  dateFormat='DD/MM/YYYY'
                  isValidDate={validStartDate}
                />
              </div>
              <div className='ml_15 width_full'>
                <IconInput
                  containerClass='mb_15 width_full'
                  withLabel
                  isDate
                  label='Start time:'
                  dateValue={currentPost.to_start_time}
                  onDateChange={(e) =>
                    setCurrentPost({
                      ...currentPost,
                      to_start_time: new Date(e),
                    })
                  }
                  icon={<Clock width='11px' height='11px' fill='' />}
                  dateFormat={false}
                />
              </div>
            </div>

            <div className='flex_row_top tab_column'>
              <div className='mr_15 width_full'>
                <IconInput
                  containerClass='mb_15 width_full'
                  withLabel
                  isDate
                  label='Expiry date:'
                  dateValue={currentPost.to_expire_date}
                  onDateChange={(e) =>
                    setCurrentPost({
                      ...currentPost,
                      to_expire_date: new Date(e),
                    })
                  }
                  icon={<DateIcon width='11px' height='11px' />}
                  dateFormat='DD/MM/YYYY'
                  isValidDate={validEndDateAlt}
                />
              </div>
              <div className='ml_15 width_full'>
                <IconInput
                  containerClass='mb_15 width_full'
                  withLabel
                  isDate
                  label='Expiry time:'
                  dateValue={currentPost.to_expire_time}
                  onDateChange={(e) =>
                    setCurrentPost({
                      ...currentPost,
                      to_expire_time: new Date(e),
                    })
                  }
                  icon={<Clock width='11px' height='11px' fill='' />}
                  dateFormat={false}
                />
              </div>
            </div>

            <Select
              isMulti
              placeholder='Select polygon(s) to apply post'
              defaultValue={defaultPolygonIds.map((id) => ({
                id,
                name: polygons.find((p) => p.id === id)?.name || id,
              }))}
              handleChange={(e) =>
                setCurrentPost({
                  ...currentPost,
                  polygon_ids: e.map((d) => d.id),
                })
              }
              containerClass='width_full '
              options={polygons}
              name='name'
              id='id'
            />
          </div>
        }
      />
      <Prompt
        showPrompt={showPrompt}
        closeModal={() => setShowPrompt(!showPrompt)}
        onSubmit={(e) => {
          e.preventDefault();
          deletePost();
        }}
        saveText='Cancel'
        loading={deleting}
        title='Are you sure you want to delete this post?'
        deleteText={deleting ? 'Deleting...' : 'Delete'}
      />
    </main>
  );
};
export default withAlert()(HomePage);
