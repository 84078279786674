import { observable, runInAction } from "mobx";
import dataService from "../../services/data.service";
import {
  GET_MEAL_CATEGORY_URL,
  UPDATE_MEAL_CATEGORY_URL,
  GET_MEAL_CATEGORY_SELECTION_TYPE_URL,
  SUPER_MEAL_CATEGORY_URL,
  REARRANGE_MEAL_CATEGORY_URL,
  REARRANGE_MEAL_URL,
} from "../../helpers/urls";
import errorHandler from "../../helpers/errorHandler";
export class MealCategoryStore {
  @observable mealCategories = [];
  @observable superMealCategories = [];
  @observable mealCategoriesSelection = [];

  @observable dataCached = false;



  getSuperMealCategories = async (alert, shouldAlert) => {
    try {
      let response, res;
      response = await dataService.getInternalData(SUPER_MEAL_CATEGORY_URL);

      runInAction(() => {
        res = response?.data?.data;
        this.superMealCategories = res;
        shouldAlert &&
          alert.show("Super meal categories loaded successfully!", {
            type: "success",
          });

        this.dataCached = true;
      });
      console.log("returned res", res);
      return res;
    } catch (error) {
      errorHandler(error, alert);
    }
  };
  getMealCategories = async (alert, shouldAlert) => {
    try {
      let response, res;
      response = await dataService.getInternalData(GET_MEAL_CATEGORY_URL);

      runInAction(() => {
        res = response?.data?.brands;
        this.mealCategories = res;
        shouldAlert &&
          alert.show("Meal categories loaded successfully!", {
            type: "success",
          });

        this.dataCached = true;
      });
      console.log("returned res", res);
      return res;
    } catch (error) {
      errorHandler(error, alert);
    }
  };

  getMealCategorySelection = async (alert) => {
    try {
      let response = await dataService.getInternalData(
        GET_MEAL_CATEGORY_SELECTION_TYPE_URL
      );

      runInAction(() => {
        let res = response && response.data && response.data.data;
        res.splice(1, 1);
        this.mealCategoriesSelection = res;

        this.dataCached = true;
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };
  updateMealCategory = async (alert, data) => {
    let response;
    try {
      response = await dataService.putAuthData(UPDATE_MEAL_CATEGORY_URL, data);

      runInAction(() => {
        if (response && response.data) {
          alert.show("Meal category updated successfully!", {
            type: "success",
          });

          this.dataCached = true;

          return response;
        }
      });
      return response;
    } catch (error) {
      errorHandler(error, alert);
    }
  };



  createMealCategory = async (alert, data) => {
    let response;
    try {
      response = await dataService.postAuthData(UPDATE_MEAL_CATEGORY_URL, data);

      runInAction(() => {
        if (response && response.data) {
          alert.show("Meal category created successfully!", {
            type: "success",
          });

          return response;
        }
      });
      return response;
    } catch (error) {
      errorHandler(error, alert);
    }
  };

  arrangeMealCategory = async (alert, data) => {
    let response;
    try {
      response = await dataService.putAuthData(REARRANGE_MEAL_CATEGORY_URL, data);
      runInAction(() => {
        if (response && response.data) {
          alert.show("Meal category rearranged successfully!", {
            type: "success",
          });
          return response;
        }
      });
      return response;
    } catch (error) {
      errorHandler(error, alert);
    }
  };
  arrangeMeal = async (alert, data) => {
    let response;
    try {
      response = await dataService.putAuthData(REARRANGE_MEAL_URL, data);

      runInAction(() => {
        if (response && response.data) {
          alert.show("Meals rearranged successfully!", {
            type: "success",
          });

          return response;
        }
      });
      return response;
    } catch (error) {
      errorHandler(error, alert);
    }
  };

  createSuperMealCategory = async (alert, data) => {
    let response;
    try {
      response = await dataService.postAuthData(SUPER_MEAL_CATEGORY_URL, data);

      runInAction(() => {
        if (response && response.data) {
          alert.show("Super meal category created successfully!", {
            type: "success",
          });

          return response;
        }
      });
      return response;
    } catch (error) {
      errorHandler(error, alert);
    }
  };
}
export default new MealCategoryStore();
