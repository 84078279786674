import React, { useState, useEffect } from "react";
import "./styles.scss";
import Header from "../../components/header";
import Tab from "../../components/tabs";
import { Button } from "../../components/buttons";
import NavItem from "../../components/sideNavItem";
import { LocationIcon } from "../../assets/icons/icons";
import Loader from "../../components/loader";
import Input from "../../components/inputs";
import commonStore, { CommonStore } from "../../stores/dataStore/commonStore";
import UserStore from "../../stores/userStore/user";
import { withAlert } from "react-alert";
import Select from "../../components/select";
import IconInput from "../../components/inputs/iconInput";
import { useHistory } from "react-router-dom";
import { ADD_SCHEDULED_NOTIFICATION, COMPLETED_SCHEDULED_NOTIFICATION, PUSH_NOTIFICATIONS } from "../../router/router";
import { Clock, DateIcon } from "../../assets/icons/icons";
import Modal from "../../components/modal";
import moment from "moment";
import TextCard from "../../components/textCard";
import Prompt from "../../components/modal/prompt";
import { ArrowBack } from "../../assets/icons/icons";

const ScheduledNotification = ({ alert }) => {
    const history = useHistory()
  //  const [subject, setSubject] = useState("");

  const [body, setBody] = useState({ title: "", body: "", users: [], notification_date: "", notification_time:"" });
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showSide, setShowSide] = useState(true);
  const [showPrompt, setShowPrompt] = useState(false);
  const [pageWidth, setPageWidth] = useState("");
  const [submitting, setSubmitting] = useState(null);
  const [currentData, setCurrentData] = useState({});
  const [users, setUsers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState("");

  const [notifications, setNotifications] = useState([]);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setPageWidth(window.innerWidth);
    if (pageWidth <= 628) {
      setShowSide(false);
    } else {
      setShowSide(true);
    }
  }, [pageWidth]);
  useEffect(() => {
    const handleWindowResize = () => {
      setPageWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, [pageWidth]);

  // load data from store
  useEffect(async () => {
    const { users, getUsers, dataCached } = UserStore;
    const{getScheduledNotifications}= commonStore;
    setLoading(true);
    await getScheduledNotifications(alert)
    setLoading(false);
    let data =commonStore.scheduledNotifications.data
    setNotifications(data)
    console.log(data)

    if (!dataCached) {
      await getUsers(alert, false);
    }

    

    setUsersOptions();
  }, []);

  // Sort users options for react select
  const setUsersOptions = () => {
    let opts = UserStore.users;
    let options = [];
    let i;
    for (i = 0; i < opts.length; i++) {
      options.push({
        ...opts[i],
        name: opts[i].first_name + " " + opts[i].last_name,
      });
    }
    setUsers(options);
  };
  const submitForm = async (e) => {
    e.preventDefault();
    const { createScheduleNotification } = commonStore;
    setSubmitting(true);
    
    let payload = { notification_title: body.title,
     notification_body: body.body,
      notification_date:  moment(body.notification_date).format("YYYY-MM-DD"),
      notification_time: moment(body.notification_time).format("HH:mm"),
      }
      console.log(payload)
    await createScheduleNotification(alert, payload);
    let data = commonStore.newNotification.scheduled_push_notification
if (data){
  data.notification_status= "pending"
}
    setNotifications([...notifications, data])
    setShowModal(!showModal)

    setSubmitting(false);
  
  };
  const updateForm = async (e) => {
    e.preventDefault();
    const { updateScheduleNotification } = commonStore;
    setSubmitting(true);
    
    let payload = { 
      notification_title: currentData.notification_title,
     notification_body: currentData.notification_body,
      notification_date:  moment(currentData.notification_date).format("YYYY-MM-DD"),
      notification_time: moment(currentData.time).format("HH:mm"),
      scheduled_push_notification_id: currentData?.id 
    }

    await updateScheduleNotification(alert, payload);
    setShowUpdateModal(!showUpdateModal)
    setSubmitting(false);
   
  
  };
  const validStartDate = current => {
    return current.isAfter(moment().subtract(1, 'day'))
  }
  const deleteNotification = async (e) => {
    e.preventDefault();
    const { deleteScheduleNotification } = commonStore;
    setSubmitting(true);
    
    let payload = { 
   "scheduled_push_notification_id": currentData?.id
      }
      let all=[...notifications]
    

      console.log(payload)
    await deleteScheduleNotification(alert, payload);
    let updated= all.filter((item)=>{return item.id!==currentData?.id})
    setShowPrompt(false)
    setCurrentData("")
    setNotifications(updated)
    setSubmitting(false);
  
  };

  function convertTo12Hour(oldFormatTime) {
    console.log("oldFormatTime: " + oldFormatTime);
    var oldFormatTimeArray = oldFormatTime.split(":");

    var HH = parseInt(oldFormatTimeArray[0]);
    var min = oldFormatTimeArray[1];

    var AMPM = HH >= 12 ? "PM" : "AM";
    var hours;
    if(HH == 0){
      hours = HH + 12;
    } else if (HH > 12) {
      hours = HH - 12;
    } else {
      hours = HH;
    }
    var newFormatTime = hours + ":" + min + " " + AMPM;
    console.log(newFormatTime);
}

  return (
    <main className="main_container">
      <div className="main_container_content">
        <Header />

        <section className="main_section">
          <div className="top_fixed">
            <Tab notifications="active_nav_item" />
            <div className="border_bottom_thick">
              <div className="flex_row_between title_bar">
             <div className="flex_row">
              <button
                    className="mr_30 cursor_pointer"
                    onClick={() => history.push(PUSH_NOTIFICATIONS)}
                  >
                    <ArrowBack width="17px" height="17px" fill="#FF0000" />
                  </button>
                <h6 className="bold_24px">Scheduled notifications</h6>
                </div>
                <div className="flex_row">
                <Button
                text="Add scheduled Notification"
                onClick={() => {
                setShowModal(!showModal)

                setModalType("create")
                }}
               className="mr_30"
              />
             
              
               <Button
                text="Completed Notifications"
                onClick={() => {
                history.push(COMPLETED_SCHEDULED_NOTIFICATION)
                }}
                

               className="mr_30"
              />
              </div>
              </div>
            </div>
          </div>
          <div className="top_section">
            <div className="mobile_visible px_50 flex_row_between">
              {pageWidth <= 628 ? (
                <button
                  className="mr_25 cursor_pointer"
                  onClick={() => setShowSide(!showSide)}
                >
                  <LocationIcon
                    fill={"#FF0000"}
                    width="18px"
                    height="18px"
                    className={showSide ? "rotate_90 ease" : "no_rotate ease"}
                  />
                </button>
              ) : null}
            </div>
          </div>
          <section className="content_section mobile_column">
            {pageWidth > 628 || showSide ? (
              <aside className={`slide_in`}></aside>
            ) : null}
            {loading ?  
            <div className="flex_column_left">
                    <h6 className="bold_24px">Loading...</h6>
                    <Loader
                      type="ThreeDots"
                      color="#ff000060"
                      height={10}
                      width={50}
                    />
                  </div>:
                  !loading && notifications.length === 0 ? 
                  
                  <h6 className="bold_24px">There are no scheduled notifications</h6>:
                  
                   <section className="cards_container px_50">
                   {
                    notifications?.filter((item )=> item.notification_status!=="done" )?.map((item, index)=> 
    
                      <div>
                               <TextCard
                                key={item && item.id}
                                cardName={item && item.notification_title}
                                duplicate
                                cardTime={`Starts on ${moment(item.notification_date).add(1,"days").format("Do-MMM-YYYY")} ${item.notification_time} `}
                               // onChange={() => onCardSelect(i)}
                                onViewClick={() => {
                                  setCurrentData(item)
                                  setShowUpdateModal(!showUpdateModal)
                                }}
                                duplicateText="Delete Notification"
                                duplicateTippy
                                onDuplicateClick={(e) => {
                                 setShowPrompt(true)
                                 setCurrentData(item)
                                }}
                              check
                                viewText='Edit Notification>'
                               cardStatus={item && item.notification_status}
                                className='image_text_card'
                                cardBody={item && item.notification_body}
                              />
                      </div>
                    )
                   }
                    </section>
                  }
           
            <section className="desktop_visible">
              
            </section>
          </section>
          
          <Modal
        title="New Scheduled Notification"
        showModal={showModal}
        closeModal={() => {setShowModal(!showModal); setCurrentData({}); setBody({}); setModalType('')}}
        customContent={
          <div>
                        <div  className="info_important">
                <p>IMPORTANT:
                  <br/>
Personalized notifications must contain exactly first_name in lower case for it to work properly</p>
</div>
                <Input
                  placeholder="Notification subject"
                  value={body.title }
                  onChange={(e) => setBody({ ...body, title: e })}
                  required
                  containerClass="width_full mb_30"
                  withLabel
                  label="Notification subject"
                />
                <div className="width_full">
                  <label className="mb_15 normal_17px">Notification body</label>
                  <textarea
                    className="text_area text_area_shorter"
                    placeholder="Notification body"
                    value={body.body}
                    onChange={(e) => setBody({ ...body, body: e.target.value })}
                    required
                  />
                </div>
                <div className="width_full">
                        <IconInput
                  containerClass="mb_15 width_full"
                  withLabel
                  isDate
                  label="Notification date:"
                  dateValue={body.notification_date }
                  onDateChange={(e) =>{
                    setBody({ ...body, notification_date:new Date(e)}) 
                  }
              
                    }
                    isValidDate={validStartDate}
                  icon={<DateIcon width="11px" height="11px" />}
                  dateFormat="DD/MM/YYYY"
              
                />
                  <IconInput
                  containerClass="mb_15 width_full"
                  withLabel
                  isDate
                  label="Notification time:"
                  dateValue={body.notification_time}
                  onDateChange={(e) =>
                    setBody({ ...body, notification_time: new Date(e) })
                  }
                  icon={<Clock width="11px" height="11px" fill="" />}
                  dateFormat={false}
                />
                
                </div>
              
          </div>
        }
        customClass="feedback_modal"
        saveText={
            submitting
              ? "Sending notification..."
              : "Send notification"
          }
        onSubmit={(e) => {
          e.preventDefault();
          if(modalType==="create"){
            submitForm(e)
          }
          else{
            updateForm(e)
          }
        
        }}
      />
       <Modal
        title="Update Scheduled Notification"
        showModal={showUpdateModal}
        closeModal={() => {setShowUpdateModal(!showUpdateModal); setCurrentData({}); setBody({}); setModalType('')}}
        customContent={
          <div>
                    <div  className="info_important">
                <p>IMPORTANT:
                  <br/>
Personalized notifications must contain exactly first_name in lower case for it to work properly</p>
</div>
                <Input
                  placeholder="Notification subject"
                  value={currentData.notification_title}
                  onChange={(e) => setCurrentData({ ...currentData, notification_title: e })}
                  required
                  containerClass="width_full mb_30"
                  withLabel
                  label="Notification subject"
                />
                <div className="width_full">
                  <label className="mb_15 normal_17px">Notification body</label>
                  <textarea
                    className="text_area text_area_shorter"
                    placeholder="Notification body"
                    value={ currentData.notification_body}
                    onChange={(e) => setCurrentData({ ...currentData, notification_body: e.target.value })}
                    required
                  />
                </div>
                
                        <IconInput
                  containerClass="mb_15 width_full"
                  withLabel
                  isDate
                  label="Notification date:"
                  dateValue={moment(currentData.notification_date).add(1,"days").format("Do-MMM-YYYY")}
                  onDateChange={(e) =>{
                    setCurrentData({ ...currentData, notification_date:new Date(e)}) 
                  }
                    }
                    isValidDate={validStartDate}
                  icon={<DateIcon width="11px" height="11px" />}
                  dateFormat="DD/MM/YYYY"
              
                />
                  <IconInput
                  containerClass="mb_15 width_full"
                  withLabel
                  isDate
                  label="Notification time:"
                  dateValue={currentData.notification_time}
                  onDateChange={(e) =>
                    setCurrentData({ ...currentData, notification_time: new Date(e) })
                  }
                  icon={<Clock width="11px" height="11px" fill="" />}
                  dateFormat={false}
                />
                </div>
              
        }
        customClass="feedback_modal"
        saveText={
            submitting
              ? "Updating notification..."
              : "Update notification"
          }
        onSubmit={(e) => {
          e.preventDefault();
           updateForm(e)
          
        
        }}
      />
      <Prompt
        showPrompt={showPrompt}
        closeModal={() => {setShowPrompt(!showPrompt); setCurrentData({})}}
        onSubmit={(e) => {
          e.preventDefault();
          deleteNotification(e);
        }}
        saveText="Cancel"
        loading={submitting}
        title="Are you sure you want to delete this Notification?"
        deleteText={submitting ? "Deleting..." : "Delete"}
      />
        </section>
        
      </div>

    </main>
  );
};
export default withAlert()(ScheduledNotification);
