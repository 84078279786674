import { 
    HOME,
    EXPLORE,
    DEALS_DISCOUNTS,
    VOUCHERS,
    PUSH_NOTIFICATIONS,
    BRAND_INFO,
    RATINGS,
    ITEM_INFO,
    TAG_CREATOR,
    CALENDER,
    LOCATION_SETTINGS,
    APP_FEEDBACKS,
    MEAL_ALLERGY,
    FAQ,
    MEAL_KEYWORD,
    COKITCHENS,
    MEAL_CATEGORIES,
    APP_VERSIONS,
    MEAL_ARRANGEMENTS,
    
 } from "../router/router";

const  ROUTES=[
{
    name:"Home page header",
    id:"1",
    type:"",
    route:HOME
},

{
    name:"Deals page & discounts",
    id:"3",
    type:"",
    route:DEALS_DISCOUNTS
},

{
    name:"Push notifications",
    id:"4",
    type:"",
    route:PUSH_NOTIFICATIONS
},



]

export default ROUTES