import React, { useState, useEffect } from "react";
import "./styles.scss";
import Header from "../../components/header";
import Tab from "../../components/tabs";
import { Button } from "../../components/buttons";
import HomeStore from "../../stores/dataStore/homeStore";
import { LocationIcon } from "../../assets/icons/icons";
import Input from "../../components/inputs";
import commonStore from "../../stores/dataStore/commonStore";
import UserStore from "../../stores/userStore/user";
import { withAlert } from "react-alert";
import Select from "../../components/select";
import { useHistory } from "react-router";
import { SCHEDULED_NOTIFICATION } from "../../router/router";
import TagsStore from "../../stores/dataStore/tagsStore";
import BrandStore from "../../stores/dataStore/brandInfoStore";
import CommonStore from "../../stores/dataStore/commonStore";
import MealCategoryStore from "../../stores/dataStore/mealCategoryStore";
import DealStore from "../../stores/dataStore/dealStore";
import {Countdown} from './countdown';

const PushNotifications = ({ alert }) => {
  const history = useHistory(SCHEDULED_NOTIFICATION);
  //  const [subject, setSubject] = useState("");

  const [body, setBody] = useState({
    title: "",
    body: "",
    users: [],
    metadata: {},
  });
  const [screenNameOptions, setScreenNameOptions] = useState([]);
  const [showSide, setShowSide] = useState(true);
  const [deals, setDeals] = useState(true);
  const [pageWidth, setPageWidth] = useState("");
  const [submitting, setSubmitting] = useState(null);
  const [users, setUsers] = useState([]);
  const [meal, setMeal] = useState("");
  const [meals, setMeals] = useState("");
  const [brand, setBrand] = useState("");
  const [brandsLoading, setBrandsLoading] = useState(false);
  const [brands, setBrands] = useState([]);
  const [cokitchens, setCokitchens] = useState([]);
  const [currentCokitchen, setCurrentCokitchen] = useState([]);
  const [loading, setLoading] = useState(false);
  const [post, setPost] = useState(false);
  const [allTags, setAllTags] = useState(false);
  const [ckLoading, setCkLoading] = useState(false);
  const [currentBrands, setCurrentBrands] = useState([]);
  const [screenName, setScreenName] = useState([]);
  const [posts, setPosts] = useState([]);
  const [deal, setDeal] = useState("");
  const [currentBrandIndex, setCurrentBrandIndex] = useState([]);
  const [counter, setCounter] = useState(0)

   

  useEffect(() => {
    setPageWidth(window.innerWidth);
    if (pageWidth <= 628) {
      setShowSide(false);
    } else {
      setShowSide(true);
    }
  }, [pageWidth]);
  useEffect(() => {
    setScreenNameOptions([
      { name: "Brand", id: "brand" },
      { name: "Deals", id: "deals" },
      { name: "Meal", id: "meal" },
      { name: "Post", id: "post" },
      { name: "Shop", id: "shop" }
    ]);
    const handleWindowResize = () => {
      setPageWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, [pageWidth]);

  const getTags = async () => {
    try {
      return await TagsStore.getAllTags(alert, false);
    } catch (error) {}
  };
  const sortAllDeals = () => {
    let allCKsCopy = [];
    let allCKs = DealStore.deals;
    let i, j, k;
    for (i = 0; i < allCKs.length; i++) {
      if (allCKs && allCKs[i] && allCKs[i].deals && allCKs[i].deals)
        for (j = 0; j < allCKs[i].deals.length; j++) {
          allCKsCopy.push(allCKs[i].deals[j]);
        }
    }
    return allCKsCopy;
  };
  // load posts from store

  // Collect posts for all cokitchens into a single array
  const sortAllPosts = () => {
    let allCKsCopy = [];
    let allCKs = HomeStore.posts;
    let i, j, k;
    for (i = 0; i < allCKs.length; i++) {
      if (
        allCKs &&
        allCKs[i] &&
        allCKs[i].cokitchen_home_page_posts &&
        allCKs[i].cokitchen_home_page_posts
      )
        for (j = 0; j < allCKs[i].cokitchen_home_page_posts.length; j++) {
          for (
            k = 0;
            k < allCKs[i].cokitchen_home_page_posts[j].posts.length;
            k++
          ) {
            allCKsCopy.push(allCKs[i].cokitchen_home_page_posts[j].posts[k]);
          }
        }
      const uniqueIds = [];
      const unique = allCKsCopy.filter((element) => {
        const isDuplicate = uniqueIds.includes(element.id);
        if (!isDuplicate) {
          uniqueIds.push(element.id);
          return true;
        }
        return false;
      });
      return unique;
    }
    return allCKsCopy;
  };

  // load data from store
  useEffect(async () => {
    let { formatTags, dataCached } = TagsStore;
    const { users, getUsers } = UserStore;
    setLoading(true);
    if (
      !BrandStore.dataAltCached ||
      !MealCategoryStore.dataCached ||
      !BrandStore.dataCached ||
      !CommonStore.dataCached ||
      !DealStore.dataCached ||
      !HomeStore.dataCached ||
      !dataCached
    ) {
      await Promise.all([
        getUsers(alert, false),
        HomeStore.getPosts(alert),
        BrandStore.getBrandsAlt(alert),
        CommonStore.getCokitchens(alert),
        DealStore.getDeals(alert),
      ]);
    }

    if (!dataCached) {
      const tags = await getTags();
      formatTags(tags);
      setAllTags();
    }
    setCokitchens(HomeStore.posts);
    //setDeals(DealStore.deals);
    setLoading(false);
    setBrands(
      BrandStore.brandsAlt
    );
    setAllTags();
    //  setCokitchens(HomeStore.posts);
    setCokitchens(HomeStore.posts);
    setCurrentCokitchen({
      cokitchen_home_page_posts: sortAllPosts(),
      name: "All users (all locations)",
    });
    setUsersOptions()

    console.log(sortAllPosts());
    let allDeals = [];
    let allPosts = [];
    sortAllDeals()?.map((item) =>
      allDeals.push({
        name: item?.heading,
        id: item?.id,
        to_expire_date: item?.to_expire_date,
      })
    );
    sortAllPosts()?.map((item) =>
      allPosts.push({
        name: item?.heading,
        id: item?.id,
        to_expire_date: item?.to_expire_date
      })
    );
  
    setPosts(allPosts)
    setDeals(allDeals );
    console.log("post and deals", allPosts, allDeals);
  }, []);

  // Set brand options based on current cokitchen
  useEffect(() => {
    let i;
    let options = [];
    let curr = cokitchens.brands || [];
    for (i = 0; i < curr.length; i++) {
      options.push({
        ...curr[i],
        label: curr[i].name,
        value: curr[i].id,
        index: i,
      });
    }
    setCurrentBrands(options?.filter((item) => item?.item_type === "FOOD"));
    console.log(options?.filter((item) => item?.item_type === "FOOD"));
  }, [currentCokitchen]);

  

  const changeBrand = (e) => {
    setBrand(e);
    setCurrentBrandIndex(e.index);
    const { getMealsOnly } = BrandStore;
    setBrandsLoading(true);
    const getCurrentMeals = async () => {
      await getMealsOnly(alert, e?.id);

      setBrandsLoading(false);

      let mealOptions = [];
      let mealCopy = BrandStore.mealsOnly;
      console.log(BrandStore.addonsOnly);
      for (let l = 0; l < mealCopy?.length; l++) {
        mealOptions.push({
          ...mealCopy[l],
          label: mealCopy[l].name,
          name: mealCopy[l]?.images[0]
            ? mealCopy[l].name + " 📸"
            : mealCopy[l].name,
          value: mealCopy[l].id,
          is_addon: mealCopy[l].is_addon,
        });
      }
      let addonOptions = [];
      let addonCopy = BrandStore.addonsOnly;
      for (let l = 0; l < addonCopy?.length; l++) {
        addonOptions.push({
          ...addonCopy[l],
          label: addonCopy[l].name,
          name: addonCopy[l]?.images[0]
            ? addonCopy[l].name + " 📸"
            : addonCopy[l].name,
          value: addonCopy[l].id,
          is_addon: addonCopy[l].is_addon,
        });
      }

      setMeals(mealOptions.filter((item) => item.is_addon === false));
    };
    getCurrentMeals();
    setAllTags();

    setMeal("");
  };
  // Set posts based on current cokitchen
  useEffect(() => {
    const currKitchenArr = currentCokitchen?.cokitchen_home_page_posts?.reduce(
      (accumulator, obj) => accumulator.concat(obj?.posts || []),
      []
    );
    let currKitchen =
      currKitchenArr?.length > 0
        ? currKitchenArr
        : currentCokitchen?.cokitchen_home_page_posts
        ? currentCokitchen?.cokitchen_home_page_posts
        : [];
    const uniqueIds = [];
    const unique = currKitchen.filter((element) => {
      const isDuplicate = uniqueIds.includes(element.id);
      if (!isDuplicate) {
        uniqueIds.push(element.id);
        return true;
      }
      return false;
    });

    // setPosts(
    //   unique?.filter((item) => new Date(item?.to_expire_date) > new Date())
    // );
  }, [currentCokitchen, cokitchens]);

  // Sort users options for react select
  const setUsersOptions = () => {
    let opts = UserStore.users;
    let options = [];
    let i;
    for (i = 0; i < opts.length; i++) {
      options.push({
        ...opts[i],
        name: opts[i].first_name + " " + opts[i].last_name,
      });
    }
    setUsers(options);
    console.log(options)
  };
  const submitForm = async (e) => {
    e.preventDefault();
    const { postPosts } = commonStore;
    if(body?.title.includes("First_name") || body?.body.includes("First_name") ){
      alert.show("'First_name' should be 'first_name'")
    }
    else{
      setCounter(180)
      setSubmitting(true);

      let payload;
      if (screenName === "meal") {
        payload = { title: body?.title, body: body?.body, metadata:{meal: {id:meal?.id, item_type:meal?.item_type, brand_id:meal?.brand_id}}, users:body.users };
      } else if (screenName === "deals") {
        let info = sortAllDeals()
          .filter((item) => item.id === deal.id)
          ?.map((item) => {
            return item;
          });
        payload = { title: body?.title, body: body?.body, metadata:{deal:{id:info[0]?.id,item_type:info[0]?.item_type}}, users:body.users };
      } else if (screenName === "post") {
        let info = sortAllPosts()
          .filter((item) => item.id === post.id)
          ?.map((item) => {
            return item;
          });
          console.log(info[0])
       
        payload = { title: body?.title, body: body?.body, metadata:{post:{id:info[0]?.id, item_type:info[0]?.item_type}}, users:body.users };
      } else if(screenName === "brand") {
        payload = { title: body?.title, body: body?.body, metadata:{brand: {id:brand?.id,item_type:brand?.item_type,cokitchen_id:brand?.cokitchen_id}} , users:body.users };
      }
      else if(screenName === "shop") {
        payload = { title: body?.title, body: body?.body, metadata:{shop: {}}, users:body.users };
      }
      else{
        payload ={title: body?.title, body: body?.body, users:body.users,metadata:{home: {}}}
      }
    
      await postPosts(alert, payload);
    
      setSubmitting(false);
    }
    
  };
  

  return (
    <main className="main_container">
      <div className="main_container_content">
        <Header />

        <section className="main_section">
          <div className="top_fixed">
            <Tab notifications="active_nav_item" />
            <div className="border_bottom_thick">
              <div className="flex_row_between title_bar">
                <h6 className="bold_24px">Push notifications</h6>
                {/* <Button
                text="Scheduled Notifications"
                onClick={() => {
                  history.push (SCHEDULED_NOTIFICATION)
                }}
               className="mr_30"
              /> */}
              </div>
            </div>
          </div>
          <div className="top_section">
            <div className="mobile_visible px_50 flex_row_between">
              {pageWidth <= 628 ? (
                <button
                  className="mr_25 cursor_pointer"
                  onClick={() => setShowSide(!showSide)}
                >
                  <LocationIcon
                    fill={"#FF0000"}
                    width="18px"
                    height="18px"
                    className={showSide ? "rotate_90 ease" : "no_rotate ease"}
                  />
                </button>
              ) : null}
            </div>
          </div>
          <section className="content_section mobile_column">
           
            <section className="cards_container mb_30 px_50">
              <form
                onSubmit={(e) => submitForm(e)}
                className="flex_column_right third_width_desktop"
              >
                <div className="info_important">
                  <p>
                    IMPORTANT:
                    <br />
                    Personalized notifications must contain exactly first_name
                    in lower case for it to work properly
                  </p>
                </div>
                <Input
                  plbelaceholder="Notification subject"
                  value={body.title}
                  onChange={(e) => setBody({ ...body, title: e })}
                  required
                  containerClass="width_full mb_30"
                  withLabel
                  label="Notification subject"
                  
                />
                <div className="width_full">
                  <label className="mb_15 normal_17px">Notification body</label>
                  <textarea
                    className="text_area text_area_shorter"
                    placeholder="Notification body"
                    value={body.body}
                    onChange={(e) => setBody({ ...body, body: e.target.value })}
                    required
                  />
                </div>
                <div className="width_full">
                  <label className="mb_15 normal_17px">
                    Select customers{" "}
                    {`   (${users?.length}). Leave this field blank to send to all customers`}
                  </label>
                  <Select
                    placeholder="Select customers"
                    defaultValue={[]}
                    isLoading={loading}
                    handleChange={(e) => {
                      let i;
                      let copy = [];
                      for (i = 0; i < e.length; i++) {
                        copy.push(e[i].id);
                      }
                      setBody({ ...body, users: copy });
                    }}
                    containerClass="width_full"
                    options={users}
                    isMulti
                    name="name"
                    id="id"
                  />
       {counter > 0 ? <Countdown counter={counter} setCounter={setCounter}/> :
         <Button
         text={
           submitting
             ? "Sending notification..."
             : "Send notification"
         }
         type="submit"
         className="mt_35 mx-50"
       /> } 
       
    
  

              
                </div>
              </form>
            </section>
            <section className="m_xl_0 second_width_desktop align_left outside_tag_container">
                  
                <h6 className="bold_18_px mb_35">Select Notification Screen</h6>
                <label className="mb_15 normal_17px">Select Screen Name</label>
                  <Select
                    placeholder="Screen Name"
                    defaultValue={[]}
                    handleChange={(e) => {
                      setScreenName(e.id);
                    }}
                    containerClass="width_full"
                    options={screenNameOptions}
                    name="name"
                    id="id"
                  />
                  {screenName === "brand" && (
                    <div>
    <label className="mb_15 normal_17px">Select Brand</label>
                    <Select
                      placeholder="Select brand"
                      defaultValue={brand}
                      handleChange={(e) => {
                        changeBrand(e);
                      }}
                      isLoading={loading}
                      containerClass="width_full"
                      options={brands}
                      name="name"
                      id="id"
                    />
                    </div>
                     
                  )}
                  {screenName === "meal" && (
                    <div>
                        <label className="mb_15 normal_17px">Select Brand</label>
                      <Select
                        placeholder="Select brand"
                        defaultValue={brand}
                        handleChange={(e) => {
                          changeBrand(e);
                        }}
                        isLoading={brandsLoading}
                        containerClass="width_full"
                        options={brands}
                        name="name"
                        id="id"
                      />
                        <label className="mb_15 normal_17px">Select Meal</label>
                      <Select
                        placeholder="Select meal"
                        value={meal}
                        handleChange={(e) => setMeal(e)}
                        containerClass="width_full"
                        options={meals}
                        name="name"
                        id="id"
                        isLoading={brandsLoading || ckLoading}
                        isDisabled={brandsLoading}
                      />{" "}
                    </div>
                  )}
                  {screenName === "post" && (
                    <div>
                      <label className="mb_15 normal_17px">Select Post</label>
                    <Select
                      placeholder="Select Post"
                      defaultValue={post}
                      handleChange={(e) => setPost(e)}
                      containerClass="width_full"
                      options={posts && posts?.filter((item)=>new Date(item.to_expire_date) > new Date())}
                      name="name"
                      id="id"
                      isLoading={loading}
                      isDisabled={loading}
                    />
                    </div>
                  )}
                  {screenName === "deals" && (
                    <div>
                       <label className="mb_15 normal_17px">Select Deal</label>
                    <Select
                      placeholder="Select Deal"
                      defaultValue={deal}
                      handleChange={(e) => setDeal(e)}
                      containerClass="width_full"
                      options={deals && deals?.filter((item)=> new Date(item.to_expire_date) > new Date())}
                      name="name"
                      id="id"
                      isLoading={loading}
                      isDisabled={loading}
                    />
                    </div>
                  )}
                  </section>
               
            <section className="desktop_visible"></section>
          </section>
          
        </section>
      </div>
    </main>
  );
};
export default withAlert()(PushNotifications);
