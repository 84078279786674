import { observable, runInAction } from "mobx";
import dataService from "../../services/data.service";
import {
  GET_DEALS_URL,
  GET_DEAL_TYPES_URL,
  DEALS_URL,
} from "../../helpers/urls";
import errorHandler from "../../helpers/errorHandler";
import { DEALS_DISCOUNTS } from "../../router/router";
import commonStore from "./commonStore";

export class DealStore {
  @observable deals = [];
  @observable dealTypes = [];
  @observable dealEligibilityTypes = [];
  @observable dealValueTypes = [];
  @observable dealRequirementTypes = [];

  @observable dataCached = false;
  @observable subDataCached = false;
  getDeals = async (alert) => {
    try {
      let response = await dataService.getInternalData(GET_DEALS_URL);

      runInAction(() => {
        let res = response?.data?.data;
        this.deals = res;
        alert.show("Deals loaded successfully!", {
          type: "success",
        });

        this.dataCached = true;
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };

  getDealTypes = async (alert) => {
    try {
      let response = await dataService.getInternalData(GET_DEAL_TYPES_URL);

      runInAction(() => {
        let res = response.data;
        this.dealTypes = res.deal_types;
        this.dealEligibilityTypes = res.deal_eligibility_types;
        this.dealValueTypes = res.deal_value_types;
        this.dealRequirementTypes = res.deal_requirement_types;
        this.subDataCached = true;
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };

  postDeals = async (alert, data, history) => {
    try {
      let response = await dataService.postAuthData(DEALS_URL, data);

      runInAction(() => {
        if (response && response.data) {
          alert.show("Deal created successfully!", {
            type: "success",
          });

          this.dataCached = true;
          commonStore.getCokitchens(alert, false);
          history.push(DEALS_DISCOUNTS);

          return response.data;
        }
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };

  updateDeal = async (alert, data, history, id) => {
    try {
      let response = await dataService.putAuthData(`${DEALS_URL}/${id}`, data);

      runInAction(() => {
        if (response && response.data) {
          alert.show("Deal updated successfully!", {
            type: "success",
          });

          this.dataCached = true;
          commonStore.getCokitchens(alert, false);
          history.push(DEALS_DISCOUNTS);
          return response.data;
        }
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };

  deleteDeals = async (id, alert, history) => {
    try {
      let response = await dataService.deleteAuthData(`${DEALS_URL}/${id}`);

      runInAction(() => {
        if (response && response.data) {
          alert.show("Deal deleted successfully!", {
            type: "success",
          });

          this.dataCached = true;
          commonStore.getCokitchens(alert, false);
          history && history.push(DEALS_DISCOUNTS);
          return response.data;
        }
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };
}
export default new DealStore();
