import { observable, runInAction, action } from "mobx";
import dataService from "../../services/data.service";
import { GET_ALL_KEYWORDS_URL, TAG_URLS } from "../../helpers/urls";
import errorHandler from "../../helpers/errorHandler";

export class TagsStore {
  @observable tags = {};
  @observable formatedTags = [];
  @observable dataCached = false;

  @action setFormatedTags = (val) => {
    this.formatedTags = val;
  };

  @action formatTags = (tags = {}) => {
    let i;
    let tagKeys = Object.keys(tags);
    let formatedTags = [];
    for (i = 2; i < tagKeys.length; i++) {
      if (typeof tags[tagKeys[i]] !== "string") {
        formatedTags.push({
          tags: tags[tagKeys[i]],
          loading: false,
          placeholder: `${tagKeys[i]}`,
          create_url: TAG_URLS[i - 2].create,
          delete_url: TAG_URLS[i - 2].delete,
          value: "",
        });
      }
    }

    this.formatedTags = formatedTags;
  };

  getAllTags = async (alert, second) => {
    this.tags = {};
    this.formatedTags = [];
    try {
      let response = await dataService.getAuthData(GET_ALL_KEYWORDS_URL);

      runInAction(() => {
        this.tags = response.data;
        !second &&
          alert.show("Tags loaded successfully!", {
            type: "success",
          });

        this.dataCached = true;
      });
      return response.data;
    } catch (error) {
      errorHandler(error, alert);
    }
  };
}
export default new TagsStore();
