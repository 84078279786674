import React from "react";
import { Button } from "../buttons";
import "./styles.scss";

const Prompt = ({
  showPrompt,
  customClass,
  onSubmit,
  closeModal,
  title,
  saveText,
  loading,
  deleteText,
}) => {
  return (
    (showPrompt && (
      <div className="flex_column_right modal prompt p_20">
        <div className="back_drop flex_column_center p_20"></div>
        <form
          className={`custom_modal_content prompt_content half_width_desktop ${customClass}`}
          onSubmit={onSubmit}
        >
          <button
            className="cursor_pointer close_button"
            onClick={closeModal}
            type="button"
          >
            x
          </button>
          <h6 className="bold_24px mb_12">{title}</h6>
          <div className="flex_row_between">
            <Button
              text={saveText}
              className="mt_35"
              loading={loading}
              type="button"
              onClick={closeModal}
            />

            <Button
              text={deleteText}
              className="mt_35"
              loading={loading}
              isRed
            />
          </div>
        </form>
      </div>
    )) ||
    null
  );
};
export default Prompt;
